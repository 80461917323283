import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as App from "../../app";
import * as Component from "../../components";
import * as Helper from "../../helpers";
import * as Util from "../../utils";

interface OrderItemListItemButtonProps {
  item: Model.Restaurant.Order.Item.Item;
  restaurantId: string;
  arrayIndex: number;
}

const OrderItemListItemButton: React.FC<OrderItemListItemButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    function handleListButtonClick(
      item: Model.Restaurant.Order.Item.Item,
      arrayIndex: number
    ) {
      return function() {
        const customizations = orderStore.getItemCustomizationsFromOrderItem(
          item
        );
        orderStore.setEditItem({
          orderItemId: item.orderItemId,
          orderItemArrayIndex: arrayIndex
        });
        // TODO: Find a way to also set the menuItem from here
        menuItemStore.setCustomizations(customizations);
        menuItemStore.setInstruction(item.instruction || "");
        menuItemStore.setQuantityCounter(item.quantity);
      };
    }

    const description = Helper.Restaurant.Order.Item.getDescription({
      addOn: props.item.addOn,
      instruction: props.item.instruction,
      required: props.item.required
    });

    return (
      <>
        {props.item.orderItemId && (
          <Mui.ListItem
            button
            disabled={
              ((orderStore.openCreateOrderAbortedDialog as unknown) as boolean) ||
              (orderStore.operatingHours &&
                !Util.Restaurant.isOpen(orderStore.operatingHours)) ||
              userStore.isStaff ||
              orderStore.isOrderCreating ||
              !orderStore.isOrderEditable
            }
            onClick={handleListButtonClick(props.item, props.arrayIndex)}
            component={Router.Link}
            to={`/restaurant/${props.restaurantId}/item/${props.item.orderItemId}`}
          >
            <Component.ItemInfo
              name={props.item.name}
              price={props.item.price}
              description={description}
              total={props.item.total}
              quantity={props.item.quantity}
              tags={props.item.tags}
            />
          </Mui.ListItem>
        )}
      </>
    );
  }
);

export default OrderItemListItemButton;
