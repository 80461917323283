import * as React from "react";
import * as Model from "../../models";
import * as Mobx from "mobx-react-lite";
import * as Component from "../../components";
import * as App from "../../app";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";

interface StaffMenuItemCustomizationOptionListItemProps {
  option: Model.Restaurant.Menu.Item.Customization.Option;
}

const StaffMenuItemCustomizationOptionListItem: React.FC<StaffMenuItemCustomizationOptionListItemProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    return (
      <>
        {userStore.isStaff && !menuItemEditStore.isEditable && (
          <Mui.ListItem>
            <Mui.ListItemText
              primary={
                <>
                  <Mui.Grid
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <Mui.Typography>{props.option.name}</Mui.Typography>
                    {props.option.price && (
                      <Styled.Mui.TypographyBold>
                        <Component.Price price={props.option.price} />
                      </Styled.Mui.TypographyBold>
                    )}
                  </Mui.Grid>
                </>
              }
            />
          </Mui.ListItem>
        )}
      </>
    );
  }
);

export default StaffMenuItemCustomizationOptionListItem;
