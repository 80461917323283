import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Constant from "../../constants";
import * as Firebase from "firebase/app";
import * as Config from "../../config";
import * as Store from "../../stores";
import * as Helper from "../../helpers";
import "firebase/functions";
import "firebase/auth";

export class Order {
  @Mobx.observable public order?: Model.Restaurant.Order.Order;
  @Mobx.observable public editItem?: Model.Restaurant.Order.Item.Edit;
  @Mobx.observable public couponCode?: string;
  @Mobx.observable public operatingHours?: Model.Restaurant.OperatingHours;
  @Mobx.observable public openCreateOrderAbortedDialog?: string;

  private createOrder = Firebase.functions().httpsCallable("createOrder");
  private createOrderWithCashfree = Firebase.functions().httpsCallable(
    "createOrderWithCashfree"
  );
  private verifyPayment = Firebase.functions().httpsCallable("verifyPayment");
  private verifyCashfreePayment = Firebase.functions().httpsCallable(
    "verifyCashfreePayment"
  );
  private validateOneTimeCoupon = Firebase.functions().httpsCallable(
    "validateOneTimeCoupon"
  );
  private paymentToken?: string;
  private vendorSplit?: string;
  @Mobx.observable public user?: Firebase.User;

  constructor() {
    Firebase.auth().onAuthStateChanged(async user => {
      const isStaff = await Helper.User.isStaff(user);
      if (!user || isStaff) {
        return;
      }
      this.user = user;
      this.rehydrate(user);
    });
    Mobx.reaction(
      () => this.order && this.order.items.length,
      () => {
        if (this.order && this.order.status) {
          return;
        }
        this.persist();
      }
    );
    Mobx.reaction(
      () => this.editItem,
      () => {
        this.persist();
      }
    );
  }

  @Mobx.action public setOpenCreateOrderAbortedDialog(message?: string) {
    this.openCreateOrderAbortedDialog = message;
  }

  @Mobx.action public checkRestaurantChange(
    mallAndRestaurantDetails: Model.Restaurant.Order.RestaurantDetails &
      Model.Restaurant.Order.MallDetails
  ): boolean {
    if (!this.order) {
      return false;
    }
    if (this.order.restaurantId === mallAndRestaurantDetails.restaurantId) {
      return false;
    }
    if (this.order.items && this.order.items.length === 0) {
      return false;
    }
    return true;
  }

  @Mobx.action public init(
    mallAndRestaurantDetails: Model.Restaurant.Order.RestaurantDetails &
      Model.Restaurant.Order.MallDetails
  ) {
    const {
      operatingHours,
      ...restOfMallAndRestaurantDetails
    } = mallAndRestaurantDetails;

    if (this.order) {
      if (this.order.restaurantId === mallAndRestaurantDetails.restaurantId) {
        return;
      }
      // this is Scenario that user is adding items from different restaurant
      // Check if items are there in the other if no silently replace order object
      // else notify the user that they are adding items from differnet restaurant
      if (this.order.items && this.order.items.length === 0) {
        this.order = {
          items: [],
          taxRate: Constant.Tax.Rate,
          ...restOfMallAndRestaurantDetails
        };
        return;
      }
    }

    this.order = {
      items: [],
      taxRate: Constant.Tax.Rate,
      ...restOfMallAndRestaurantDetails
    };
  }

  @Mobx.action public reset(wholeReset?: boolean) {
    if (wholeReset) {
      this.order = undefined;
      this.editItem = undefined;
      this.couponCode = undefined;
      return;
    }

    if (!this.order) {
      this.editItem = undefined;
      this.couponCode = undefined;
      return;
    }

    if (this.order.orderId) {
      this.order = undefined;
      this.editItem = undefined;
      this.couponCode = undefined;
    }
  }

  @Mobx.action private rehydrate(user: Firebase.User) {
    const order: Model.Restaurant.Order.Order | null = JSON.parse(
      localStorage.getItem(`order@${user.uid}`) as string
    );

    if (!order) {
      return;
    }

    this.order = order;
  }

  private persist() {
    if (!this.user || !this.order) {
      return;
    }

    localStorage.setItem(`order@${this.user.uid}`, JSON.stringify(this.order));
  }

  private destroy() {
    if (!this.user) {
      return;
    }

    localStorage.removeItem(`order@${this.user.uid}`);
  }

  @Mobx.action public setUserEmail(email: string) {
    if (!this.order) {
      return;
    }

    this.order.userEmail = email;
  }

  @Mobx.action public setOrderTimeToPrepare(minutes: number) {
    if (!this.order) {
      return;
    }

    this.order.orderTime = minutes;
  }

  @Mobx.computed get isOrderEditable(): boolean | undefined {
    if (!this.order) {
      return undefined;
    }

    return this.order.razorPayOrderId ? false : true;
  }

  @Mobx.computed get isOrderCreating(): boolean | undefined {
    if (!this.order) {
      return undefined;
    }

    return this.order.status === "created" && !this.order.orderId
      ? true
      : false;
  }

  @Mobx.action public async requestCreateOrder(
    userId: string,
    userMobile: string,
    userEmail: string | null
  ) {
    if (!this.order) {
      return;
    }

    this.order.userId = userId;
    this.order.userMobile = userMobile;
    this.order.userEmail = this.order.userEmail || userEmail;
    this.order.status = "created";
    this.order.createdAt = Firebase.firestore.Timestamp.fromDate(new Date());
    this.order.paymentStatus = "pending";
    this.order.statusUpdates = {
      accepted: false,
      closed: false,
      paid: false,
      ready: false
    };
    this.order.cancelled = false;
    this.order.subTotal = this.subTotal;
    this.order.taxTotal = this.taxTotal;
    this.order.total = this.total;

    try {
      const response: {
        data: { orderId: string; razorPayOrderId: string };
      } = await this.createOrder(this.order);

      this.order.orderId = response.data.orderId;
      this.order.razorPayOrderId = response.data.razorPayOrderId;
      return;
    } catch (error) {
      if (error.code === "unknown") {
        this.openCreateOrderAbortedDialog = error.message;
      }
    }
  }

  @Mobx.action public async requestCreateOrderWithCashfree(
    userId: string,
    userMobile: string
  ) {
    if (!this.order) {
      return;
    }

    this.order.userId = userId;
    this.order.userMobile = userMobile;
    this.order.status = "created";
    this.order.createdAt = Firebase.firestore.Timestamp.fromDate(new Date());
    this.order.paymentStatus = "pending";
    this.order.statusUpdates = {
      accepted: false,
      closed: false,
      paid: false,
      ready: false
    };
    this.order.cancelled = false;
    this.order.subTotal = this.subTotal;
    this.order.taxTotal = this.taxTotal;
    this.order.total = this.total;

    try {
      const response: {
        data: {
          orderId: string;
          paymentToken: string;
          vendorSplit: string;
        };
      } = await this.createOrderWithCashfree(this.order);

      this.paymentToken = response.data.paymentToken;
      this.vendorSplit = response.data.vendorSplit;
      this.order.orderId = response.data.orderId;
      this.order.razorPayOrderId = response.data.orderId;
      return;
    } catch (error) {
      console.error(error);
      if (error.code === "unknown") {
        this.openCreateOrderAbortedDialog = error.message;
      }
    }
  }

  @Mobx.action public mockRequestCreateOrder(
    userId: string,
    userMobile: string,
    userEmail?: string | null
  ) {
    return new Promise((resolve, reject) => {
      if (!this.order) {
        return;
      }

      this.order.userId = userId;
      this.order.userMobile = userMobile;
      this.order.userEmail = this.order.userEmail || userEmail;
      this.order.status = "created";
      this.order.createdAt = Firebase.firestore.Timestamp.fromDate(new Date());
      this.order.paymentStatus = "pending";
      this.order.subTotal = this.subTotal;
      this.order.taxTotal = this.taxTotal;
      this.order.total = this.total;
      setTimeout(() => {
        if (!this.order) {
          return;
        }

        this.order.orderId = "DMt1TYNC0Ax5CyKcNc0O";
        this.order.razorPayOrderId = "order_Dos2uk0WLtUqJy";
        resolve();
      }, 4000);
    });
  }

  @Mobx.action public processPayment(toastStore: Store.Toast) {
    if (!this.order || !this.order.orderId || !this.order.razorPayOrderId) {
      return;
    }

    const checkout = new window.Razorpay({
      key: Config.Razorpay.keyId,
      order_id: this.order.razorPayOrderId,
      name: this.order.restaurantName,
      description: `Order # ${this.order.orderId}, Razorpay Order # ${this.order.razorPayOrderId}`,
      prefill: {
        contact: this.order.userMobile ? this.order.userMobile : undefined,
        email: this.order.userEmail ? this.order.userEmail : undefined
      },
      modal: {
        ondismiss: () =>
          console.log("Payment modal dismissed without completing the payment.")
      },
      handler: (response: Razorpay.AuthResponse) => {
        this.requestVerifyPayment(response, toastStore);
      }
    });

    checkout.open();
  }

  @Mobx.action public processCashfreePayment(toastStore: Store.Toast) {
    if (
      !this.order ||
      !this.order.orderId ||
      !this.order.razorPayOrderId ||
      !this.order.userMobile ||
      !this.paymentToken
    ) {
      return;
    }

    const data: CashFree.PaymentGateway.Checkout.Data = {
      orderId: this.order.orderId,
      orderAmount: this.total,
      customerPhone: this.order.userMobile,
      appId: Config.Cashfree.appId,
      paymentToken: this.paymentToken,
      vendorSplit: this.vendorSplit
    };

    const callback = (
      event: CashFree.PaymentGateway.Checkout.Payment.Event
    ) => {
      switch (event.name) {
        case "PAYMENT_RESPONSE":
          this.requestProcessCashfreePayment(event.response, toastStore);
          break;

        default:
          break;
      }
    };

    window.CashFree.makePayment(data, callback);
  }

  @Mobx.action public async requestVerifyPayment(
    response: Razorpay.AuthResponse,
    toastStore: Store.Toast
  ) {
    if (!this.order) {
      return;
    }

    try {
      const result: { data: boolean } = await this.verifyPayment(response);
      const isPaymentVerified = result.data;
      if (isPaymentVerified) {
        this.order.razorPayPaymentId = response.razorpay_payment_id;
        this.order.paidAt = Firebase.firestore.Timestamp.fromDate(new Date());
        this.order.status = "paid";
        this.order.paymentStatus = "paid";
        toastStore.showToast({
          body: "Order successfully placed."
        });
        this.destroy();
      } else {
        this.order.paymentStatus = "failed";
        toastStore.showToast({
          body: "Payment failed."
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.action public async requestProcessCashfreePayment(
    response: CashFree.PaymentGateway.Checkout.Payment.Response,
    toastStore: Store.Toast
  ) {
    if (!this.order) {
      return;
    }

    try {
      const result: { data: boolean } = await this.verifyCashfreePayment(
        response
      );
      const isPaymentVerified = result.data;
      this.order.razorPayPaymentId = response.referenceId;
      this.order.paidAt = Firebase.firestore.Timestamp.fromDate(new Date());
      this.order.status = "paid";
      this.order.paymentStatus = "paid";
      if (isPaymentVerified) {
        toastStore.showToast({
          body: "Order successfully placed."
        });
        this.destroy();
      } else {
        this.order.paymentStatus = "failed";
        toastStore.showToast({
          body: "Payment failed."
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Mobx.action public addItem(item?: Model.Restaurant.Order.Item.Item) {
    if (!this.order || !item) {
      return;
    }

    if (this.editItem && this.editItem.orderItemId === item.orderItemId) {
      this.order.items[this.editItem.orderItemArrayIndex] = item;
      this.editItem = undefined;
      return;
    } else {
      this.editItem = undefined;
    }

    this.order.items.push(item);
  }

  @Mobx.action public removeItem() {
    if (!this.order || !this.editItem) {
      return;
    }

    this.order.items.splice(this.editItem.orderItemArrayIndex, 1);
    this.editItem = undefined;
  }

  @Mobx.action public setEditItem(editItem?: Model.Restaurant.Order.Item.Edit) {
    this.editItem = editItem;
  }

  @Mobx.computed public get subTotal(): number {
    if (!this.order || this.order.items.length === 0) {
      return 0;
    }

    const subTotal = this.order.items.reduce((subTotal: number, item) => {
      return subTotal + item.total;
    }, 0);

    return subTotal;
  }

  @Mobx.computed public get taxTotal(): number {
    if (!this.order) {
      return 0;
    }

    const taxTotal = this.subTotal * (this.order.taxRate / 100);

    return taxTotal;
  }

  @Mobx.computed public get totalDiscount(): number {
    if (
      !(this.order && this.order.isDiscountApplied && this.order.totalDiscount)
    ) {
      return 0;
    }

    return this.order.totalDiscount;
  }

  @Mobx.computed public get total(): number {
    if (!this.order) {
      return 0;
    }

    const total = this.subTotal + this.taxTotal - this.totalDiscount;
    return total;
  }

  public getOrderItemDescription({
    addOn,
    required,
    instruction
  }: {
    addOn?: Model.Restaurant.Order.Item.Option[];
    required?: Model.Restaurant.Order.Item.Option[];
    instruction?: string;
  }): string {
    return [...required, ...addOn, instruction]
      .filter(value => value)
      .map(option => {
        if (typeof option === "string") {
          return option;
        }
        if (!option) {
          return undefined;
        }
        return option.name;
      })
      .join(", ");
  }

  public getItemCustomizationsFromOrderItem(
    orderItem: Model.Restaurant.Order.Item.Item
  ): Map<string, Map<string, Model.Restaurant.Order.Item.Option>> | undefined {
    if (!orderItem.addOn && !orderItem.required) {
      return undefined;
    }

    const customizations: Map<
      string,
      Map<string, Model.Restaurant.Order.Item.Option>
    > = new Map();

    [...orderItem.addOn, ...orderItem.required].forEach(option => {
      const customizationId = (option.addOnCustomizationId ||
        option.requiredCustomizationId) as string;
      const checkedOptionsAlreadyExists = customizations.get(customizationId);

      if (!checkedOptionsAlreadyExists) {
        const checkedOptions = new Map();
        checkedOptions.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptions);
      }

      if (checkedOptionsAlreadyExists) {
        checkedOptionsAlreadyExists.set(option.optionId as string, option);
        customizations.set(customizationId, checkedOptionsAlreadyExists);
      }
    });

    return customizations;
  }

  @Mobx.action public async removeCoupon() {
    if (!this.order) {
      return;
    }
    this.order.isDiscountApplied = false;
    this.order.totalDiscount = 0;
  }

  @Mobx.action public async applyCoupon() {
    if (!this.order || !(this.couponCode && this.couponCode.trim() !== "")) {
      return;
    }
    // Also need to check if the user is phone verified I.E logged ub
    if (!(this.user && this.user.uid)) {
      return;
    }
    const data = {
      userId: this.user.uid,
      restaurantId: this.order.restaurantId,
      couponCode: this.couponCode,
      orderValue: this.subTotal
    };

    const result: {
      data: { discount: number; valid: boolean; reason?: string };
    } = await this.validateOneTimeCoupon(data);

    if (result && result.data) {
      if (result.data.valid) {
        this.order.isDiscountApplied = true;
        this.order.totalDiscount = result.data.discount!;
      } else {
        this.order.isDiscountApplied = false;
        this.order.totalDiscount = 0;
      }
      return result.data;
    }
  }

  @Mobx.action handleCouponInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.couponCode = event.target.value;
  };
}
