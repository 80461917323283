import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as MuiTransition from "@material-ui/core/transitions";
import * as Layout from "../../layouts";
import * as Yup from "yup";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import * as ReactFire from "reactfire";
import * as App from "../../app";
import * as Container from "../../containers";
import * as Component from "../../components";

interface UserSignInDialogProps {
  open: boolean;
  handleClose(showOTP?: boolean): void;
}

const Transition = React.forwardRef<unknown, MuiTransition.TransitionProps>(
  function Transition(props, ref) {
    return <Mui.Slide direction="up" ref={ref} {...props} />;
  }
);

const UserSignInDialog: React.FC<UserSignInDialogProps> = props => {
  const userStore = React.useContext(App.Context.User.User);
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  const theme = Mui.useTheme();

  const validationSchema = Yup.object<Model.Form.UserSignIn>({
    mobile: Yup.string()
      .matches(/^\d{10}$/, "Mobile is invalid")
      .required("Mobile is required"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required")
  });

  // Firebase.auth().settings.appVerificationDisabledForTesting = true;
  const firebaseApp = ReactFire.useFirebaseApp();
  // const user = firebaseApp.auth().currentUser;

  async function handleSubmit(
    { mobile, email }: Model.Form.UserSignIn,
    { setSubmitting }: Formik.FormikHelpers<Model.Form.UserSignIn>
  ) {
    orderStore.setUserEmail(email);
    userStore.setEmail(email);

    const verifier = new Firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );

    try {
      window.confirmationResult = await firebaseApp
        .auth()
        .signInWithPhoneNumber(`+91${mobile.trim()}`, verifier);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      props.handleClose(true);
    }
  }

  return (
    <>
      <Mui.Dialog
        fullScreen
        open={props.open}
        onClose={() => props.handleClose()}
        TransitionComponent={Transition}
        PaperComponent={Styled.Page.Paper}
      >
        <Layout.Dev variant={Layout.VARIANT.DIALOG}>
          <Container.AppBar
            title={<Mui.Typography variant="h6">Login</Mui.Typography>}
            // TODO: This is a cheap fix done to remove the default back button from
            // the AppBar refactor it when time allows
            startActionArea={<></>}
            endActionArea={
              <>
                <Mui.IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => props.handleClose()}
                >
                  <Icon.Close />
                </Mui.IconButton>
              </>
            }
          />
          <Styled.Page.Container>
            {/* <Styled.Mui.TypographyBold variant="h5">
              User details
            </Styled.Mui.TypographyBold>
            <Mui.Typography variant="body2" color="textSecondary" gutterBottom>
              We need your Mobile number and Email ID for communicating the
              status of your order.
            </Mui.Typography> */}
            <Component.ErrorInfo
              picture="/images/login.svg"
              title="Treat yourself!"
              body="Welcome! We've been missing you all this time."
            />
            <Formik.Formik
              initialValues={{ mobile: "", email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Formik.Form>
                  <Mui.Grid container spacing={1}>
                    <Mui.Grid item xs={12}>
                      <Formik.Field
                        type="tel"
                        name="mobile"
                        component={FormikMui.TextField}
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">
                              +91
                            </Mui.InputAdornment>
                          )
                        }}
                        label="Mobile"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Formik.Field
                        type="email"
                        name="email"
                        component={FormikMui.TextField}
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Button
                        type="submit"
                        variant="contained"
                        disabled={!isValid || isSubmitting}
                        fullWidth
                        color="primary"
                      >
                        {isSubmitting ? <Mui.CircularProgress /> : "Login"}
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                </Formik.Form>
              )}
            </Formik.Formik>
            <div style={{ marginTop: theme.spacing(2) }}>
              <Mui.Typography variant="caption" color="textSecondary">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </Mui.Typography>
            </div>

            {/* {user && user.phoneNumber && (
              <>
                <Styled.Mui.ContainerPaddingBottom maxWidth="sm">
                  <Styled.Footer.Fixed>
                    <Mui.Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => props.handleClose()}
                    >
                      Continue with {user.phoneNumber}
                    </Mui.Button>
                  </Styled.Footer.Fixed>
                </Styled.Mui.ContainerPaddingBottom>
              </>
            )} */}
          </Styled.Page.Container>
        </Layout.Dev>
      </Mui.Dialog>
    </>
  );
};

export default UserSignInDialog;
