/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as MuiTransition from "@material-ui/core/transitions";
import * as Layout from "../../layouts";
import * as App from "../../app";
import * as Component from "../../components";

interface TimeToPrepareDialogProps {
  open: boolean;
  handleClose(showOTP?: boolean): void;
}

const Transition = React.forwardRef<unknown, MuiTransition.TransitionProps>(
  function Transition(props, ref) {
    return <Mui.Slide direction="up" ref={ref} {...props} />;
  }
);

const TimeToPrepareDialog: React.FC<TimeToPrepareDialogProps> = props => {
  const [timeToPrepare, setTimeToPrepare] = React.useState<number>(45);

  // const userStore = React.useContext(App.Context.User.User);
  const orderStore = React.useContext(App.Context.Restaurant.Order);
  // const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  // React.useEffect(() => {
  //   // TODO: check for the restaurants default preparation time
  //   if (restaurantStore.restaurant) {
  //     setTimeToPrepare(50);
  //   }
  // }, []);

  function handleSubmit() {
    return function() {
      try {
        // TODO: Do something here
        orderStore.setOrderTimeToPrepare(timeToPrepare);
      } catch (error) {
        console.error(error);
      } finally {
        props.handleClose();
      }
    };
  }

  return (
    <>
      <Mui.Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        TransitionComponent={Transition}
        PaperComponent={Styled.Page.Paper}
      >
        <Layout.Dev variant={Layout.VARIANT.DIALOG}>
          <Styled.Page.Container>
            <Mui.Typography variant="subtitle1" gutterBottom>
              How long will this take to prepare in minutes?
            </Mui.Typography>
            <Component.Counter
              min={5}
              max={60}
              step={1}
              counter={timeToPrepare}
              setCounter={setTimeToPrepare}
            />
            <Mui.Button
              fullWidth
              variant="contained"
              size="large"
              onClick={handleSubmit()}
            >
              DONE
            </Mui.Button>
          </Styled.Page.Container>
        </Layout.Dev>
      </Mui.Dialog>
    </>
  );
};

export default TimeToPrepareDialog;
