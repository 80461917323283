import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as Component from "../../components";

interface OrderInfoListItemTextProps {
  order: Model.Restaurant.Order.Order;
}

const OrderInfoListItemText: React.FC<OrderInfoListItemTextProps> = props => {
  return (
    <>
      <Mui.ListItemText
        disableTypography
        primary={
          <>
            <Mui.Grid container justify="space-between">
              {props.order.orderId && (
                <Styled.Mui.TypographyBold variant="h5">
                  # {props.order.orderId.slice(-5).toUpperCase()}
                </Styled.Mui.TypographyBold>
              )}
            </Mui.Grid>
          </>
        }
        secondary={
          <>
            {props.order.total && <Component.Price price={props.order.total} />}
            {" | "}
            {props.order.items
              .map(item => {
                return `${item.name}`;
              })
              .join(", ")}
          </>
        }
      />
    </>
  );
};

export default OrderInfoListItemText;
