import * as Mui from "@material-ui/core";
import ExpansionPanelSummary from "./expansion-panel-summary";

const ExpansionPanelSummaryDisabled = Mui.styled(ExpansionPanelSummary)(
  ({ theme }) => ({
    backgroundColor: theme.palette.action.hover
  })
);

export default ExpansionPanelSummaryDisabled;
