import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface StaffOrdersAppBarTitleProps {}

const StaffOrdersAppBarTitle: React.FC<StaffOrdersAppBarTitleProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const { pathname } = Router.useLocation();
    const isOrdersPage = pathname.includes("/orders");

    return (
      <>
        {isOrdersPage && userStore.isStaff && (
          <>
            <Mui.Typography variant="h6">My Orders</Mui.Typography>
          </>
        )}
      </>
    );
  }
);

export default StaffOrdersAppBarTitle;
