import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface MenuAppBarTitleProps {}

const MenuAppBarTitle: React.FC<MenuAppBarTitleProps> = Mobx.observer(props => {
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const theme = Mui.useTheme();
  const { pathname } = Router.useLocation();
  const isMenuPage = pathname.includes("/menu");

  return (
    <>
      {isMenuPage && restaurantStore.restaurant && (
        <>
          <Mui.Typography variant="h6">
            {restaurantStore.restaurant.name}
          </Mui.Typography>
          <Mui.Typography
            variant="body2"
            style={{ marginTop: theme.spacing(-1) }}
          >
            {restaurantStore.restaurant.address}
          </Mui.Typography>
        </>
      )}
    </>
  );
});

export default MenuAppBarTitle;
