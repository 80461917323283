/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

interface IUseCheckoutParams {
  callback: () => void;
}

const useCashfreeCheckout = ({ callback }: IUseCheckoutParams) => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.cashfree.com/assets/cashfree.sdk.v1.2.js";
    script.async = true;
    script.onload = callback;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useCashfreeCheckout;
