import * as Model from "../../../../../models";

export function getCheckedOptions(
  customization: Model.Restaurant.Menu.Item.CuratedCustomization,
  customizations: Map<
    string,
    Map<string, Model.Restaurant.Menu.Item.Customization.Option>
  >
): Map<string, Model.Restaurant.Menu.Item.Customization.Option> | undefined {
  const customizationId: string = (customization.addOnCustomizationId ||
    customization.requiredCustomizationId) as string;

  return customizations.get(customizationId);
}
