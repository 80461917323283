import * as React from "react";
import * as Mui from "@material-ui/core";
import * as MuiButton from "@material-ui/core/Button";
import * as Model from "../../models";

interface OrderBadgeProps {
  status: Model.Restaurant.Order.Status | "cancelled";
}

const StyledMuiButton = Mui.styled(
  ({ status, ...rest }: MuiButton.ButtonProps & OrderBadgeProps) => (
    <Mui.Button {...rest} />
  )
)(({ theme }) => ({
  "&:disabled": {
    backgroundColor: (props: OrderBadgeProps) =>
      (props.status === "cancelled" && Mui.colors.red[500]) ||
      ((props.status === "ready" ||
        props.status === "paid" ||
        props.status === "created") &&
        Mui.colors.green[500]) ||
      (props.status === "accepted" && Mui.colors.amber[500]) ||
      undefined,
    color: (props: OrderBadgeProps) =>
      props.status === "closed" || props.status === "accepted"
        ? theme.palette.common.black
        : theme.palette.common.white
  }
}));

const OrderBadge: React.FC<OrderBadgeProps> = props => {
  return (
    <>
      <StyledMuiButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        disabled
        size="small"
        variant="contained"
        color={props.status === "closed" ? "default" : "inherit"}
        {...props}
      >
        {(props.status === "paid" || props.status === "created") && "New"}
        {props.status === "accepted" && "Preparing"}
        {props.status !== "paid" &&
          props.status !== "created" &&
          props.status !== "accepted" &&
          props.status}
      </StyledMuiButton>
    </>
  );
};

export default OrderBadge;
