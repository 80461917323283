import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";

interface BackButtonProps {}

const BackButton: React.FC<BackButtonProps> = props => {
  const userStore = React.useContext(App.Context.User.User);
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  const history = Router.useHistory();
  const { pathname } = Router.useLocation();

  function handleBackButtonClick() {
    orderStore.setEditItem();
    history.goBack();
  }

  const isOrderPage =
    !pathname.includes("/orders") && pathname.includes("/order");
  const isMenuItemPage = pathname.includes("/item/");
  const isNotMenuPage = !pathname.includes("/menu");
  const isNotRestaurantsPage = !pathname.includes("/mall/");
  const isNotMallsPage = pathname !== "/";

  return (
    <>
      {userStore.isStaff && (isOrderPage || isMenuItemPage) && (
        <Mui.IconButton
          edge="start"
          color="inherit"
          onClick={handleBackButtonClick}
        >
          <Icon.ArrowBack />
        </Mui.IconButton>
      )}
      {!userStore.isStaff &&
        isNotMenuPage &&
        isNotRestaurantsPage &&
        isNotMallsPage && (
          <Mui.IconButton
            edge="start"
            color="inherit"
            onClick={handleBackButtonClick}
          >
            <Icon.ArrowBack />
          </Mui.IconButton>
        )}
    </>
  );
};

export default BackButton;
