import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as Styled from "../../styles";

interface PageNotFoundProps {}

const PageNotFound: React.FC<PageNotFoundProps> = props => {
  const [, setErrorBoundry] = React.useState();

  function triggerErrorBoundry() {
    setErrorBoundry(() => {
      throw new Error("Testing error boundry");
    });
  }

  function handleStartFresh() {
    window.location.replace("/");
  }

  return (
    <>
      <Styled.Page.Container>
        <Component.ErrorInfo
          title="404: The page you are looking for isn’t here"
          body="You either tried some shady route or you came here by mistake. Whichever it is, try doing a fresh start."
          picture="/images/404.svg"
        >
          <Mui.Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 16 }}
          >
            <Mui.Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleStartFresh}
            >
              Start Fresh
            </Mui.Button>
          </Mui.Grid>
        </Component.ErrorInfo>
      </Styled.Page.Container>
      TODO: Remove this after use
      <Mui.Button fullWidth onClick={triggerErrorBoundry}>
        Trigger Error Boundry
      </Mui.Button>
    </>
  );
};

export default Component.withTracker(PageNotFound);
