import * as React from "react";
import * as Mui from "@material-ui/core";

interface PaymentBadgeProps {
  status: "paid" | "captured" | "failed" | "pending";
}

const StyledMuiChip = Mui.withStyles(theme => ({
  colorPrimary: {
    backgroundColor: (props: PaymentBadgeProps) =>
      ((props.status === "paid" || props.status === "captured") &&
        Mui.colors.green[500]) ||
      (props.status === "failed" && Mui.colors.red[500]) ||
      ((props.status === "pending" && Mui.colors.amber[500]) as string),
    color: (props: PaymentBadgeProps) =>
      props.status === "failed"
        ? theme.palette.common.white
        : theme.palette.common.black
  }
}))(Mui.Chip);

const PaymentBadge: React.FC<PaymentBadgeProps> = props => {
  return (
    <>
      <StyledMuiChip
        {...props}
        color="primary"
        size="small"
        label={props.status.toUpperCase()}
      />
    </>
  );
};

export default PaymentBadge;
