import * as React from "react";
import * as Model from "../../models";
import * as Mobx from "mobx-react-lite";
import * as Component from "../../components";
import * as App from "../../app";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Formik from "formik";
import * as Yup from "yup";

interface StaffMenuItemEditCustomizationOptionListItemProps {
  option: Model.Restaurant.Menu.Item.Customization.Option;
  customizationArrayIndex: number;
  arrayIndex: number;
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
}

const StaffMenuItemEditCustomizationOptionListItem: React.FC<StaffMenuItemEditCustomizationOptionListItemProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    const validationSchema = Yup.object<
      Model.Form.Restaurant.Menu.Item.Customization.Option.Edit
    >({
      name: Yup.string().required("Name is required"),
      price: Yup.number()
        .positive("Cannot be negative")
        .notRequired()
    });

    async function handleSubmit(
      values: Model.Form.Restaurant.Menu.Item.Customization.Option.Edit,
      {
        setSubmitting
      }: Formik.FormikHelpers<
        Model.Form.Restaurant.Menu.Item.Customization.Option.Edit
      >
    ) {
      menuItemEditStore.setEditedCustomizationOption(
        props.customization,
        props.customizationArrayIndex,
        props.arrayIndex,
        {
          ...props.option,
          ...values
        } as Model.Restaurant.Menu.Item.Customization.Option
      );
      setSubmitting(false);
    }

    function handleDeleteClick() {
      menuItemEditStore.removeCustomizationOption(
        props.customization,
        props.customizationArrayIndex,
        props.arrayIndex
      );
    }

    return (
      <>
        {userStore.isStaff && menuItemEditStore.isEditable && (
          <Mui.ListItem>
            <Mui.ListItemText
              disableTypography
              primary={
                <>
                  <Formik.Formik
                    validationSchema={validationSchema}
                    initialValues={{
                      name: props.option.name,
                      price:
                        props.option.price || (("" as unknown) as undefined)
                    }}
                    onSubmit={handleSubmit}
                  >
                    {() => (
                      <Formik.Form style={{ width: "100%" }}>
                        <Mui.Grid
                          container
                          spacing={0}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Mui.Grid item xs>
                            <Mui.Typography component="div">
                              <Formik.Field
                                name="name"
                                type="text"
                                component={
                                  Component.FormikMui.TextField.AutoSubmitting
                                }
                                fullWidth
                                placeholder="Item Name *"
                                margin="dense"
                                required
                              />
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={3}>
                            <Styled.Mui.TypographyBold component="div">
                              <Formik.Field
                                name="price"
                                type="number"
                                component={
                                  Component.FormikMui.TextField.AutoSubmitting
                                }
                                InputProps={{
                                  startAdornment: (
                                    <Mui.InputAdornment position="start">
                                      {restaurantStore.restaurant &&
                                      restaurantStore.restaurant.currency
                                        ? restaurantStore.restaurant.currency
                                            .symbol
                                        : "₹"}
                                    </Mui.InputAdornment>
                                  )
                                }}
                                fullWidth
                                placeholder={"Price"}
                                margin="dense"
                              />
                            </Styled.Mui.TypographyBold>
                          </Mui.Grid>
                          <Mui.Grid
                            item
                            xs={2}
                            style={{ maxWidth: "max-content" }}
                          >
                            <Mui.IconButton onClick={handleDeleteClick}>
                              <Icon.Delete />
                            </Mui.IconButton>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Formik.Form>
                    )}
                  </Formik.Formik>
                </>
              }
            />
          </Mui.ListItem>
        )}
      </>
    );
  }
);

export default StaffMenuItemEditCustomizationOptionListItem;
