import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";

interface MenuAppBarBackButtonProps {}

const MenuAppBarBackButton: React.FC<MenuAppBarBackButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

    const { pathname } = Router.useLocation();
    const isMenuPage = pathname.includes("/menu");

    return (
      <>
        {isMenuPage && !userStore.isStaff && restaurantStore.restaurant && (
          <>
            <Mui.IconButton
              edge="start"
              color="inherit"
              component={Router.Link}
              to={`/mall/${restaurantStore.restaurant.mallId}`}
            >
              <Icon.ArrowBack />
            </Mui.IconButton>
          </>
        )}
      </>
    );
  }
);

export default MenuAppBarBackButton;
