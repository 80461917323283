/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Router from "react-router-dom";
import * as ReactFire from "reactfire";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as Styled from "../../styles";
import * as App from "../../app";
import * as Model from "../../models";

interface HomeProps {}

const useStyles = Mui.makeStyles((theme: Mui.Theme) =>
  Mui.createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      minWidth: 300,
      width: "100%"
    },
    image: {
      position: "relative",
      height: 200,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important", // Overrides inline-style
        height: 100
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15
        },
        "& $imageMarked": {
          opacity: 0
        },
        "& $imageTitle": {
          fontSize: "bold"
        }
      }
    },
    focusVisible: {},
    imageButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%"
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create("opacity")
    },
    imageTitle: {
      position: "relative",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
        6}px`
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      bottom: -2,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity")
    }
  })
);

const Home: React.FC<HomeProps> = Mobx.observer(props => {
  const classes = useStyles();

  const mallsRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("malls");

  const userStore = React.useContext(App.Context.User.User);
  const mallStore = React.useContext(App.Context.Mall.Mall);

  React.useEffect(() => {
    mallStore.subscribe(mallsRef, "Chennai");
  }, []);

  const history = Router.useHistory();

  const { pathname } = Router.useLocation();

  function handleClick(mall: Model.Mall) {
    return function() {
      if (!mall.isLive) {
        return;
      }
      mallStore.setActiveMall(mall);
      history.push(`/mall/${mall.mallId}`);
    };
  }

  return (
    <>
      {pathname === "/" && userStore.isStaff && userStore.user && (
        <Router.Redirect
          to={`/restaurant/${
            (userStore.user as Model.Staff).restaurantId
          }/orders`}
        />
      )}

      {mallStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {/* <Styled.Page.Title>
              <Styled.Mui.TypographyBold variant="h5">
                Places
              </Styled.Mui.TypographyBold>
            </Styled.Page.Title> */}
            <Mui.Grid container spacing={2}>
              {mallStore.malls.map(mall => (
                <Mui.Grid key={`Mall#${mall.mallId}`} item xs={12} sm={6}>
                  <Mui.Card>
                    <Mui.CardActionArea
                      focusRipple
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: "100%"
                      }}
                      onClick={handleClick(mall)}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          backgroundImage: `url(${mall.coverImage})`
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Mui.Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {mall.name}
                        </Mui.Typography>

                        <Mui.Typography
                          component="span"
                          variant="caption"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {mall.isLive && mall.outlets ? (
                            <>{mall.outlets.length} outlets</>
                          ) : (
                            `Coming Soon!`
                          )}
                        </Mui.Typography>
                      </span>
                    </Mui.CardActionArea>
                  </Mui.Card>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Home);
