import * as React from "react";
import * as Formik from "formik";
import * as Yup from "yup";
import * as Component from "../../components";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Model from "../../models";
import * as App from "../../app";
import * as Styled from "../../styles";

interface StaffMenuItemEditCustomizationInfoProps {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
  customizationArrayIndex: number;
}

const StaffMenuItemEditCustomizationInfo: React.FC<StaffMenuItemEditCustomizationInfoProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    const maxSelections = props.customization.options.filter(
      option => option.name
    ).length;
    const validationSchema = Yup.object<
      Model.Form.Restaurant.Menu.Item.Customization.Edit
    >({
      name: Yup.string().required("Name is required"),
      min: Yup.number()
        .positive("Cant be negative")
        .max(maxSelections, "Invalid")
        .notRequired(),
      max: Yup.number()
        .positive("Cant be negative")
        .max(maxSelections, "Invalid")
        .required("Max is required")
    });

    async function handleSubmit(
      values: Model.Form.Restaurant.Menu.Item.Customization.Edit,
      {
        setSubmitting
      }: Formik.FormikHelpers<
        Model.Form.Restaurant.Menu.Item.Customization.Edit
      >
    ) {
      menuItemEditStore.setEditedCustomization(
        {
          ...props.customization,
          ...values
        } as Model.Restaurant.Menu.Item.CuratedCustomization,
        props.customizationArrayIndex
      );
      setSubmitting(false);
    }

    function handleDeleteClick() {
      menuItemEditStore.removeCustomization(
        props.customization,
        props.customizationArrayIndex
      );
    }

    const theme = Mui.useTheme();

    return (
      <>
        {userStore.isStaff && menuItemEditStore.isEditable && (
          <Mui.Grid container justify="space-between" alignItems="baseline">
            <Formik.Formik
              validationSchema={validationSchema}
              initialValues={{
                name: props.customization.name,
                min: props.customization.min || (("" as unknown) as number),
                max: props.customization.max || (("" as unknown) as number)
              }}
              onSubmit={handleSubmit as any}
            >
              {() => (
                <Formik.Form style={{ width: "100%" }}>
                  <Mui.Grid
                    container
                    spacing={0}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Mui.Typography variant="h6">
                      {props.customization.requiredCustomizationId && (
                        <Mui.Typography
                          component="span"
                          color="error"
                          variant="h6"
                        >
                          *&nbsp;
                        </Mui.Typography>
                      )}
                    </Mui.Typography>
                    <Mui.Grid item xs>
                      <Mui.Typography component="div">
                        <Formik.Field
                          name="name"
                          type="text"
                          component={
                            Component.FormikMui.TextField.AutoSubmitting
                          }
                          fullWidth
                          placeholder="Customization Name *"
                          margin="dense"
                          required
                        />
                      </Mui.Typography>
                    </Mui.Grid>
                    {props.customization.requiredCustomizationId && (
                      <Mui.Grid item xs={2}>
                        <Styled.Mui.TypographyBold component="div">
                          <Formik.Field
                            name="min"
                            type="number"
                            component={
                              Component.FormikMui.TextField.AutoSubmitting
                            }
                            InputProps={{
                              startAdornment: (
                                <Mui.InputAdornment position="start">
                                  #
                                </Mui.InputAdornment>
                              )
                            }}
                            fullWidth
                            placeholder={"Min"}
                            margin="dense"
                          />
                        </Styled.Mui.TypographyBold>
                      </Mui.Grid>
                    )}
                    <Mui.Grid item xs={2}>
                      <Styled.Mui.TypographyBold component="div">
                        <Formik.Field
                          name="max"
                          type="number"
                          component={
                            Component.FormikMui.TextField.AutoSubmitting
                          }
                          InputProps={{
                            startAdornment: (
                              <Mui.InputAdornment position="start">
                                #
                              </Mui.InputAdornment>
                            )
                          }}
                          fullWidth
                          placeholder={"Max"}
                          margin="dense"
                          required
                        />
                      </Styled.Mui.TypographyBold>
                    </Mui.Grid>
                    <div
                      style={{
                        position: "relative",
                        top: theme.spacing(-3.5),
                        right: theme.spacing(-3.5)
                      }}
                    >
                      <Mui.Fab
                        size="small"
                        color="secondary"
                        onClick={handleDeleteClick}
                      >
                        <Icon.Close />
                      </Mui.Fab>
                    </div>
                  </Mui.Grid>
                </Formik.Form>
              )}
            </Formik.Formik>
          </Mui.Grid>
        )}
      </>
    );
  }
);

export default StaffMenuItemEditCustomizationInfo;
