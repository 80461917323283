import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface StaffMenuAddCategoryCardProps {}

const StaffMenuAddCategoryCard: React.FunctionComponent<StaffMenuAddCategoryCardProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );

    function handleClick() {
      categoryEditStore.addNewEmptyCategory();
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          categoryEditStore.isEditable && (
            <Mui.ExpansionPanel expanded={true}>
              <Mui.Button color="primary" fullWidth onClick={handleClick}>
                Add new menu category
              </Mui.Button>
            </Mui.ExpansionPanel>
          )}
      </>
    );
  }
);

export default StaffMenuAddCategoryCard;
