import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as Firebase from "firebase/app";
import "firebase/auth";

interface AccountProps {}

const Account: React.FC<AccountProps> = Mobx.observer(props => {
  const userStore = React.useContext(App.Context.User.User);
  const userAccountStore = React.useContext(App.Context.User.Account);

  const [openUserSignInDialog, setOpenUserSignInDialog] = React.useState<
    boolean
  >(false);
  const [openMobileOTPDialog, setOpenMobileOTPDialog] = React.useState<boolean>(
    false
  );

  const [user, setUser] = React.useState<Firebase.User | null>(null);

  Firebase.auth().onAuthStateChanged(user => setUser(user));

  async function handleLoginClick() {
    setOpenUserSignInDialog(true);
  }

  function handleUserSignInDialogClose(showOTP?: boolean) {
    setOpenUserSignInDialog(false);
    if (showOTP) {
      setOpenMobileOTPDialog(true);
      return;
    }
  }

  function handleMobileOTPDialogClose() {
    setOpenMobileOTPDialog(false);
  }
  return (
    <>
      <Component.UserSignInDialog
        open={openUserSignInDialog}
        handleClose={handleUserSignInDialogClose}
      />
      <Component.MobileOTPDialog
        open={openMobileOTPDialog}
        handleClose={handleMobileOTPDialogClose}
      />
      {userStore.isStaff && <Router.Redirect to="/" />}
      {userAccountStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {/* {user && user.phoneNumber && (
              <Styled.Page.Title>
                <Styled.Mui.TypographyBold variant="h5">
                  My Account
                  <Mui.Typography color="textSecondary">
                    Phone: {user.phoneNumber}
                  </Mui.Typography>
                  {userStore.user && userStore.user.email && (
                    <Mui.Typography color="textSecondary">
                      Email: {userStore.user.email}
                    </Mui.Typography>
                  )}
                </Styled.Mui.TypographyBold>
              </Styled.Page.Title>
            )} */}
            {user &&
              user.phoneNumber &&
              userAccountStore.pastOrders.length > 0 && (
                <>
                  <Styled.Page.Title>
                    <Styled.Mui.TypographyBold variant="h5">
                      My Past Orders
                    </Styled.Mui.TypographyBold>
                  </Styled.Page.Title>
                  {userAccountStore.pastOrders.map((order, index) => (
                    <React.Fragment key={`Order#${order.orderId}-${index}`}>
                      <Container.OrderInfoCard order={order} />
                    </React.Fragment>
                  ))}
                </>
              )}
            {user &&
              user.phoneNumber &&
              userAccountStore.pastOrders.length === 0 && (
                <>
                  <Component.ErrorInfo
                    title="No past orders found."
                    body="If you are in one of the malls / restaurants we service and you feel hungry? Order with us for a pleasant experience."
                    picture="/images/no-past-orders.svg"
                  />
                </>
              )}
            {user && user.isAnonymous && (
              <>
                <Component.ErrorInfo
                  title="Almost there."
                  body="Login or Signup to view your past orders."
                  picture="/images/before-login.svg"
                >
                  <Mui.Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: 16 }}
                  >
                    <Mui.Button
                      color="primary"
                      variant="contained"
                      autoFocus
                      onClick={handleLoginClick}
                    >
                      Login
                    </Mui.Button>
                  </Mui.Grid>
                </Component.ErrorInfo>
              </>
            )}
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Account);
