import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffOrdersNewOrderButtonProps {}

const StaffOrdersNewOrderButton: React.FC<StaffOrdersNewOrderButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const staffOrdersStore = React.useContext(App.Context.Staff.Order);

    function handleClick(order: Model.Restaurant.Order.Order) {
      return function() {
        orderStore.order = order;
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId &&
          staffOrdersStore.newOrders.length > 0 && (
            <>
              <Mui.Button
                variant="contained"
                size="large"
                color="secondary"
                fullWidth
                onClick={handleClick(staffOrdersStore.newOrders[0])}
                component={Router.Link}
                to={`/order`}
              >
                <Mui.Grid container justify="space-between" alignItems="center">
                  <Mui.Grid item>You have a new order!</Mui.Grid>
                  <Mui.Grid item>
                    <Icon.Navigation />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Button>
            </>
          )}
      </>
    );
  }
);

export default StaffOrdersNewOrderButton;
