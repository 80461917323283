import * as React from "react";
import * as Router from "react-router-dom";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffMenuItemEditButtonProps {}

const StaffMenuItemEditButton: React.FC<StaffMenuItemEditButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    const history = Router.useHistory();

    function handleClick(
      item: Model.Restaurant.Menu.CuratedItem,
      restaurantId: string
    ) {
      return function() {
        menuItemEditStore.setItem(item);
        menuItemEditStore.setIsEditable(true);

        if (!menuItemEditStore.item) {
          return;
        }

        history.push(
          `/restaurant/${restaurantId}/item/${menuItemEditStore.item
            .menuItemId as string}/edit`
        );
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId &&
          menuItemStore.menuItem &&
          !menuItemEditStore.isEditable && (
            <Mui.Button
              onClick={handleClick(
                menuItemStore.menuItem,
                restaurantStore.restaurant.restaurantId
              )}
              color="primary"
              variant="contained"
              fullWidth
            >
              Edit
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffMenuItemEditButton;
