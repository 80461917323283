import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Firebase from "firebase/app";
import "firebase/auth";

interface OrderLoginToContinueActionProps {}

const OrderLoginToContinueAction: React.FC<OrderLoginToContinueActionProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    // const toastStore = React.useContext(App.Context.Toast);

    const [openUserSignInDialog, setOpenUserSignInDialog] = React.useState<
      boolean
    >(false);
    const [openMobileOTPDialog, setOpenMobileOTPDialog] = React.useState<
      boolean
    >(false);

    const [user, setUser] = React.useState<Firebase.User | null>(null);

    Firebase.auth().onAuthStateChanged(user => setUser(user));

    async function handleLoginClick() {
      setOpenUserSignInDialog(true);
    }

    function handleUserSignInDialogClose(showOTP?: boolean) {
      setOpenUserSignInDialog(false);
      if (showOTP) {
        setOpenMobileOTPDialog(true);
        return;
      }
    }

    function handleMobileOTPDialogClose() {
      setOpenMobileOTPDialog(false);
    }

    return (
      <>
        <Component.UserSignInDialog
          open={openUserSignInDialog}
          handleClose={handleUserSignInDialogClose}
        />
        <Component.MobileOTPDialog
          open={openMobileOTPDialog}
          handleClose={handleMobileOTPDialogClose}
        />
        {!userStore.isStaff &&
          user &&
          user.isAnonymous &&
          orderStore.order &&
          !orderStore.order.status &&
          orderStore.isOrderEditable && (
            <>
              <Mui.Typography variant="h6">Almost there</Mui.Typography>
              <Mui.Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
              >
                Login or Signup to place your order
              </Mui.Typography>
              <Mui.Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleLoginClick}
              >
                Continue
              </Mui.Button>
            </>
          )}
      </>
    );
  }
);

export default OrderLoginToContinueAction;
