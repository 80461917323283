import * as Model from "../../../../../models";
import * as Mobx from "mobx";
import uuidv4 from "uuid/v4";
import "firebase/firestore";

export class Edit {
  @Mobx.observable public isEditable?: boolean;
  private originalCustomization?: Model.Restaurant.Menu.Item.CuratedCustomization;
  @Mobx.observable
  public customization?: Model.Restaurant.Menu.Item.CuratedCustomization;

  @Mobx.action public reset() {
    this.setIsEditable();
    this.originalCustomization = undefined;
    this.customization = undefined;
  }

  @Mobx.action public setIsEditable(isEditable?: boolean) {
    this.isEditable = isEditable;
  }

  @Mobx.action public setOriginalCustomization(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization
  ) {
    this.originalCustomization = customization;
    this.customization = customization;
  }

  @Mobx.action public addNewEmptyCustomizationOption() {
    if (!this.customization) {
      return;
    }

    this.customization.options.push({
      optionId: uuidv4(),
      name: "",
      price: undefined
    } as Model.Restaurant.Menu.Item.Customization.Option);
  }
}
