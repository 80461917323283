import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as MuiTransition from "@material-ui/core/transitions";
import * as Layout from "../../layouts";
import * as App from "../../app";
import * as Icon from "@material-ui/icons";

interface StaffOrderReadyAcknowledgementDialogProps {
  open: boolean;
  isLoading: boolean;
  handleClose(): void;
}

const Transition = React.forwardRef<unknown, MuiTransition.TransitionProps>(
  function Transition(props, ref) {
    return <Mui.Slide direction="up" ref={ref} {...props} />;
  }
);

const StaffOrderReadyAcknowledgementDialog: React.FC<StaffOrderReadyAcknowledgementDialogProps> = props => {
  const userStore = React.useContext(App.Context.User.User);
  // const orderStore = React.useContext(App.Context.Restaurant.Order);

  return (
    <>
      {userStore.isStaff && (
        <Mui.Dialog
          open={props.open}
          onClose={() => props.handleClose()}
          TransitionComponent={Transition}
          PaperComponent={Styled.Page.Paper}
        >
          <Layout.Dev variant={Layout.VARIANT.DIALOG}>
            <Styled.Page.Container>
              <Mui.Grid
                style={{ height: 200, width: 200 }}
                container
                justify="center"
                alignItems="center"
              >
                {props.isLoading && <Mui.CircularProgress />}
                {!props.isLoading && (
                  <>
                    <Mui.Zoom in={!props.isLoading}>
                      <Mui.Typography
                        variant="h2"
                        style={{ padding: 0, margin: 0, lineHeight: 0 }}
                      >
                        <Icon.CheckCircle color="primary" fontSize="inherit" />
                      </Mui.Typography>
                    </Mui.Zoom>
                  </>
                )}
              </Mui.Grid>
            </Styled.Page.Container>
          </Layout.Dev>
        </Mui.Dialog>
      )}
    </>
  );
};

export default StaffOrderReadyAcknowledgementDialog;
