import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Model from "../../models";
import * as Mobx from "mobx-react-lite";
import * as Util from "../../utils";

interface MenuItemAddToOrderButtonProps {}

const MenuItemAddToOrderButton: React.FC<MenuItemAddToOrderButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const history = Router.useHistory();

    function handleClick(
      mallAndRestaurantDetails: Model.Restaurant.Order.MallDetails &
        Model.Restaurant.Order.RestaurantDetails
    ) {
      return function() {
        try {
          menuItemStore.validateRequiredCustomizations();
          const restaurantChanged: boolean = orderStore.checkRestaurantChange(
            mallAndRestaurantDetails
          );
          if (restaurantChanged) {
            handleClickOpen();
            return;
          }
          orderStore.init(mallAndRestaurantDetails);
          orderStore.addItem(menuItemStore.asOrderItem);

          history.push(
            `/restaurant/${mallAndRestaurantDetails.restaurantId}/menu`
          );
          toastStore.showToast({
            body: "Item added to Order"
          });
        } catch (error) {
          console.error(error);

          toastStore.showToast({
            ...((error as Error).name && { title: (error as Error).name }),
            body: (error as Error).message
          });
        }
      };
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleReplaceCartItem = () => {
      // This scenario will be called form the dialog box
      // The validation for the item is already done in this scenario
      //  Hence instantate the object and then add the item to the cart
      orderStore.reset(true);
      const mallAndRestaurantDetails = {
        mallId: restaurantStore!.restaurant!.mallId!,
        mallName: restaurantStore!.restaurant!.mallName!,
        restaurantAddress: restaurantStore!.restaurant!.address!,
        restaurantId: restaurantStore!.restaurant!.restaurantId!,
        restaurantName: restaurantStore!.restaurant!.name!,
        operatingHours: restaurantStore!.restaurant!.operatingHours
      };
      orderStore.init(mallAndRestaurantDetails);
      orderStore.addItem(menuItemStore.asOrderItem);
      setOpen(false);
      history.push(`/restaurant/${mallAndRestaurantDetails.restaurantId}/menu`);
      toastStore.showToast({
        body: "Item added to Order"
      });
    };

    return (
      <>
        {!userStore.isStaff &&
          menuItemStore.menuItem &&
          !orderStore.editItem &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <>
              <Mui.Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={
                  !Util.Restaurant.isOpen(
                    restaurantStore.restaurant.operatingHours
                  )
                }
                onClick={handleClick({
                  mallId: restaurantStore.restaurant.mallId,
                  mallName: restaurantStore.restaurant.mallName,
                  restaurantAddress: restaurantStore.restaurant.address,
                  restaurantId: restaurantStore.restaurant.restaurantId,
                  restaurantName: restaurantStore.restaurant.name,
                  operatingHours: restaurantStore.restaurant.operatingHours
                })}
              >
                <Component.TwoLabel
                  label1={"Add to Order"}
                  label2={<Component.Price price={menuItemStore.itemTotal} />}
                />
              </Mui.Button>
              <Mui.Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Mui.DialogTitle id="alert-dialog-title">
                  Replace Cart Item ?
                </Mui.DialogTitle>
                <Mui.DialogContent>
                  <Mui.DialogContentText id="alert-dialog-description">
                    Your cart contains dishes from{" "}
                    {orderStore.order && orderStore.order.restaurantName}. Do
                    you want to discard the items and add dishes from{" "}
                    {restaurantStore.restaurant.name}?
                  </Mui.DialogContentText>
                </Mui.DialogContent>
                <Mui.DialogActions>
                  <Mui.Button onClick={handleClose} color="primary" autoFocus>
                    NO
                  </Mui.Button>
                  <Mui.Button
                    onClick={handleReplaceCartItem}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Mui.Button>
                </Mui.DialogActions>
              </Mui.Dialog>
            </>
          )}
      </>
    );
  }
);

export default MenuItemAddToOrderButton;
