import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffOrdersFilterBarProps {}

const StaffOrdersFilterBar: React.FC<StaffOrdersFilterBarProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const staffOrderStore = React.useContext(App.Context.Staff.Order);

    const theme = Mui.useTheme();

    function changeFilter(filter: Model.Restaurant.Order.Filter) {
      return function() {
        staffOrderStore.setActiveFilter(filter);
      };
    }

    return (
      <>
        {userStore.isStaff && restaurantStore.restaurant && (
          <>
            <Mui.Grid container justify="flex-start" alignItems="center">
              <Mui.Grid item xs={10}>
                <div
                  style={{
                    overflow: "auto",
                    whiteSpace: "nowrap"
                  }}
                >
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    size="small"
                    variant={
                      staffOrderStore.activeFilter === "all"
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      staffOrderStore.activeFilter === "all"
                        ? "primary"
                        : "default"
                    }
                    onClick={changeFilter("all")}
                  >
                    All
                  </Mui.Button>
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    variant={
                      staffOrderStore.activeFilter === "preparing"
                        ? "contained"
                        : "outlined"
                    }
                    size="small"
                    color={
                      staffOrderStore.activeFilter === "preparing"
                        ? "primary"
                        : "default"
                    }
                    onClick={changeFilter("preparing")}
                  >
                    Preparing
                  </Mui.Button>
                  <Mui.Button
                    style={{ marginRight: theme.spacing(1) }}
                    variant={
                      staffOrderStore.activeFilter === "ready"
                        ? "contained"
                        : "outlined"
                    }
                    size="small"
                    color={
                      staffOrderStore.activeFilter === "ready"
                        ? "primary"
                        : "default"
                    }
                    onClick={changeFilter("ready")}
                  >
                    Ready
                  </Mui.Button>
                </div>
              </Mui.Grid>

              <Mui.Grid item xs={1} style={{ maxWidth: "max-content" }}>
                <Mui.IconButton>
                  <Icon.Search />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          </>
        )}
      </>
    );
  }
);

export default StaffOrdersFilterBar;
