import * as React from "react";
import * as Mui from "@material-ui/core";

interface TimerProps {
  startTimestamp: number;
  duration: number;
}

const Timer: React.FC<TimerProps> = props => {
  const [timerProgress, setTimerProgress] = React.useState<number>(100);
  const [timerLeft, setTimerLeft] = React.useState<number>(props.duration);

  React.useEffect(() => {
    function timerProgressUpdate(): number {
      const now = new Date().getTime();
      const diffInMs = now - props.startTimestamp;
      const diffInMinutes = diffInMs / (1000 * 60);
      const progress =
        diffInMinutes > props.duration
          ? 0
          : 100 - (diffInMinutes / props.duration) * 100;

      setTimerProgress(progress);
      setTimerLeft(props.duration - diffInMinutes);

      return diffInMinutes;
    }

    const updateInterval = setInterval(() => {
      const diffInMinutes = timerProgressUpdate();

      if (diffInMinutes > props.duration) {
        clearInterval(updateInterval);
      }
    }, 60000);

    const diffInMinutes = timerProgressUpdate();

    if (diffInMinutes > props.duration) {
      clearInterval(updateInterval);
    }
    return () => {
      clearInterval(updateInterval);
    };
  }, [props.duration, props.startTimestamp]);

  return (
    <>
      <Mui.Grid container direction="column" alignItems="flex-end">
        <Mui.Typography variant="body1">Ready for pickup in</Mui.Typography>
        <Mui.Grid item>
          <div
            style={{
              color:
                timerProgress > 50
                  ? Mui.colors.lightGreen[500]
                  : timerProgress < 50 && timerProgress > 25
                  ? Mui.colors.amber[500]
                  : timerProgress < 25 && timerProgress > 0
                  ? Mui.colors.red[500]
                  : Mui.colors.grey[500]
            }}
          >
            <Mui.CircularProgress
              color="inherit"
              variant="static"
              value={timerProgress < 0 ? 100 : timerProgress}
              size={50}
            />
          </div>
          <Mui.Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              position: "relative",
              bottom: 54,
              marginBottom: -108
            }}
            align="center"
          >
            {timerLeft < 0 ? 0 : timerLeft.toFixed(0)}
            <Mui.Typography variant="body2" style={{ marginTop: -10 }}>
              mins
            </Mui.Typography>
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default Timer;
