import * as Model from "../../../../../../models";
import * as Util from "../../../../../../utils";

export function addOn(
  addOnCustomization?: Model.Restaurant.Menu.Item.Customization.AddOn
): Model.Restaurant.Menu.Item.CuratedCustomization[] | undefined {
  if (!addOnCustomization) {
    return;
  }

  const addOnCustomizations = Object.keys(addOnCustomization).map(key => ({
    ...addOnCustomization[key],
    options: Util.Curate.Restaurant.Menu.Item.Customization.options(
      addOnCustomization[key].options
    ),
    addOnCustomizationId: key
  }));

  return addOnCustomizations;
}
