import React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
// import * as Icon from "@material-ui/icons";
import * as Container from "../../containers";
import * as Styled from "../../styles";

const useStyles = Mui.makeStyles((theme: Mui.Theme) =>
  Mui.createStyles({
    appBar: {
      right: "unset",
      maxWidth: "inherit"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  })
);

export interface AppBarProps {
  title?: React.ReactNode;
  startActionArea?: React.ReactNode;
  endActionArea?: React.ReactNode;
}

const AppBar: React.FC<AppBarProps> = (props: AppBarProps) => {
  const classes = useStyles();

  const { pathname } = Router.useLocation();

  const isMenuItemPage = pathname.includes("/item/");
  const is404Page = pathname.includes("/404");
  const isHomePage = pathname === "/";

  const [openCityChangeDialog, setOpenCityChangeDialog] = React.useState<
    boolean
  >(false);

  function onAppBarClick() {
    return function() {
      if (!isHomePage) {
        return;
      }

      console.log("I should only see this in home page");
      setOpenCityChangeDialog(true);
    };
  }

  function handleCityChangeDialogClose() {
    setOpenCityChangeDialog(false);
  }

  return (
    <>
      <Container.CityChangeDialog
        open={openCityChangeDialog}
        onClose={handleCityChangeDialogClose}
      />
      {!isMenuItemPage && !is404Page && (
        <Styled.Header.AppBar>
          <Mui.AppBar
            onClick={onAppBarClick()}
            className={classes.appBar}
            position="fixed"
          >
            <Mui.Toolbar>
              {props.startActionArea ? (
                props.startActionArea
              ) : (
                <>
                  <Container.BackButton />
                  <Container.MenuAppBarBackButton />
                  <Container.RestaurantsAppBarBackButton />
                </>
              )}
              <div className={classes.title}>
                {props.title ? (
                  props.title
                ) : (
                  <>
                    <Container.HomeAppBarTitle />
                    <Container.RestaurantsAppBarTitle />
                    {/* NOTE: For now the menu item app bar title will not be shown */}
                    {/* TODO: For now show the menu item category on the header for all menu item pages*/}
                    <Container.MenuItemAppBarTitle />
                    <Container.MenuAppBarTitle />
                    <Container.AccountAppBarTitle />
                    <Container.OrderAppBarTitle />
                    <Container.StaffOrdersAppBarTitle />
                    <Container.StaffOrdersHistoryAppBarTitle />
                  </>
                )}
              </div>
              {props.endActionArea ? (
                props.endActionArea
              ) : (
                <>
                  {/* <Container.HomeAppBarCityChangeButton /> */}
                  <Container.OrderAppBarTag />
                  <Container.AccountAppBarLogoutButton />
                </>
              )}
              {/* <Mui.Button color="inherit">Login</Mui.Button> */}
            </Mui.Toolbar>
          </Mui.AppBar>
          <Mui.Toolbar />
        </Styled.Header.AppBar>
      )}
    </>
  );
};

export default AppBar;
