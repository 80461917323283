import * as Model from "../../../../models";

export function getDescription({
  addOn,
  required,
  instruction
}: {
  addOn?: Model.Restaurant.Order.Item.Option[];
  required?: Model.Restaurant.Order.Item.Option[];
  instruction?: string;
}): string {
  return [...required, ...addOn, instruction]
    .filter(value => value)
    .map(option => {
      if (typeof option === "string") {
        return option;
      }
      if (!option) {
        return undefined;
      }
      return option.name;
    })
    .join(", ");
}
