import * as Model from "../../../../models";
import * as Mobx from "mobx";
import uuidv4 from "uuid/v4";

export class Edit {
  @Mobx.observable public isEditable?: boolean;
  @Mobx.observable public item?: Model.Restaurant.Menu.CuratedItem;

  @Mobx.computed
  public get asRestaurantMenuItem(): Model.Restaurant.Menu.Item.Item {
    if (!this.item) {
      throw new Error("Menu item is undefined");
    }

    if (!this.item.name || !this.item.price) {
      throw new Error("Menu item name and price is required");
    }

    if (this.item.price && this.item.price < 0) {
      throw new Error("Menu item price can't be negative");
    }

    const addOnCustomizations =
      this.item.addOnCustomization &&
      this.item.addOnCustomization
        .filter(customization => customization.name)
        .reduce(
          (
            addOnCustomizations: Model.Restaurant.Menu.Item.Customization.AddOn,
            customization
          ) => {
            const {
              addOnCustomizationId,
              ...restOfCustomization
            } = customization;
            const options = customization.options
              .filter(option => option.name)
              .map(option => {
                if (option.price && option.price < 0) {
                  throw new Error(
                    `${customization.name} has an error in option ${option.name}`
                  );
                }
                return option;
              })
              .reduce(
                (
                  options: Model.Restaurant.Menu.Item.Customization.Options,
                  option
                ) => {
                  const { optionId, ...restOfOption } = option;
                  return { ...options, [optionId as string]: restOfOption };
                },
                {} as Model.Restaurant.Menu.Item.Customization.Options
              );

            // if (customization.max > Object.keys(options).length) {
            //   throw new Error(
            //     `For ${customization.name} customization max number of selections allowed is greater than number of valid options.`
            //   );
            // }

            if (Object.keys(options).length === 0) {
              return addOnCustomizations;
            }

            return {
              ...addOnCustomizations,
              [addOnCustomizationId as string]: {
                ...restOfCustomization,
                options
              }
            };
          },
          {} as Model.Restaurant.Menu.Item.Customization.AddOn
        );

    const requiredCustomizations =
      this.item.requiredCustomization &&
      this.item.requiredCustomization
        .filter(customization => customization.name)
        .reduce(
          (
            requiredCustomizations: Model.Restaurant.Menu.Item.Customization.Required,
            customization
          ) => {
            const {
              requiredCustomizationId,
              ...restOfCustomization
            } = customization;
            const options = customization.options
              .filter(option => option.name)
              .map(option => {
                if (option.price && option.price < 0) {
                  throw new Error(
                    `${customization.name} has an error in option ${option.name}`
                  );
                }
                return option;
              })
              .reduce(
                (
                  options: Model.Restaurant.Menu.Item.Customization.Options,
                  option
                ) => {
                  const { optionId, ...restOfOption } = option;
                  return { ...options, [optionId as string]: restOfOption };
                },
                {} as Model.Restaurant.Menu.Item.Customization.Options
              );

            // if (customization.max > Object.keys(options).length) {
            //   throw new Error(
            //     `For ${customization.name} customization max number of selections allowed is greater than number of valid options.`
            //   );
            // }

            // if (Object.keys(options).length === 0) {
            //   return addOnCustomizations;
            // }

            return {
              ...requiredCustomizations,
              [requiredCustomizationId as string]: {
                ...restOfCustomization,
                options
              }
            };
          },
          {} as Model.Restaurant.Menu.Item.Customization.Required
        );

    const menuItem: Model.Restaurant.Menu.Item.Item = {
      ...(addOnCustomizations && { addOnCustomization: addOnCustomizations }),
      available: this.item.available,
      categoryId: this.item.categoryId,
      description: this.item.description,
      displayOrder: this.item.displayOrder,
      extraDescription: this.item.extraDescription,
      menuItemId: this.item.menuItemId,
      name: this.item.name,
      price: this.item.price,
      tags: this.item.tags,
      ...(requiredCustomizations && {
        requiredCustomization: requiredCustomizations
      })
    };
    return menuItem;
  }

  @Mobx.action public reset() {
    this.item = undefined;
    this.isEditable = undefined;
  }

  @Mobx.action public toggleAvailability() {
    if (!this.item) {
      return;
    }

    this.item.available = !this.item.available;
  }

  @Mobx.action public toggleTags() {
    if (!this.item) {
      return;
    }
    if (!(this.item.tags && this.item.tags.length > 0)) {
      this.item.tags = ["veg"];
      return;
    }
    if (this.item.tags[0] === "veg") {
      this.item.tags = ["non-veg"];
      return;
    }
    if (this.item.tags[0] === "non-veg") {
      this.item.tags = ["veg"];
      return;
    }
  }

  @Mobx.action public setIsEditable(isEditable: boolean) {
    this.isEditable = isEditable;
  }

  @Mobx.action public setItem(item: Model.Restaurant.Menu.CuratedItem) {
    this.item = item;
  }

  @Mobx.action public setEditedItem(
    values: Pick<
      Model.Restaurant.Menu.CuratedItem,
      "description" | "extraDescription" | "name" | "price"
    >
  ) {
    if (!this.item) {
      return;
    }

    this.item = { ...this.item, ...values };
  }

  @Mobx.action public addNewEmptyItem(categoryId: string) {
    this.item = {
      addOnCustomization: [],
      available: false,
      categoryId,
      description: "",
      displayOrder: 0,
      extraDescription: "",
      menuItemId: uuidv4(),
      name: "",
      price: 0,
      requiredCustomization: [],
      tags: ["non-veg"]
    } as Model.Restaurant.Menu.CuratedItem;
  }

  @Mobx.action public addNewEmptyCustomizationOption(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization,
    customizationArrayIndex: number
  ) {
    if (!this.item) {
      return;
    }

    if (
      customization.addOnCustomizationId &&
      this.item.addOnCustomization &&
      this.item.addOnCustomization[customizationArrayIndex]
    ) {
      this.item.addOnCustomization[customizationArrayIndex].options.push({
        optionId: uuidv4(),
        name: "",
        price: undefined
      } as Model.Restaurant.Menu.Item.Customization.Option);
      return;
    }

    if (
      this.item.requiredCustomization &&
      this.item.requiredCustomization[customizationArrayIndex]
    ) {
      this.item.requiredCustomization[customizationArrayIndex].options.push({
        optionId: uuidv4(),
        name: "",
        price: undefined
      } as Model.Restaurant.Menu.Item.Customization.Option);
      return;
    }
  }

  @Mobx.action public removeCustomizationOption(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization,
    customizationArrayIndex: number,
    optionArrayIndex: number
  ) {
    if (!this.item) {
      return;
    }

    if (
      customization.addOnCustomizationId &&
      this.item.addOnCustomization &&
      this.item.addOnCustomization[customizationArrayIndex]
    ) {
      this.item.addOnCustomization[customizationArrayIndex].options.splice(
        optionArrayIndex,
        1
      );
      return;
    }

    if (
      this.item.requiredCustomization &&
      this.item.requiredCustomization[customizationArrayIndex]
    ) {
      this.item.requiredCustomization[customizationArrayIndex].options.splice(
        optionArrayIndex,
        1
      );
      return;
    }
  }

  @Mobx.action public setEditedCustomizationOption(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization,
    customizationArrayIndex: number,
    optionArrayIndex: number,
    option: Model.Restaurant.Menu.Item.Customization.Option
  ) {
    if (!this.item) {
      return;
    }

    if (
      customization.addOnCustomizationId &&
      this.item.addOnCustomization &&
      this.item.addOnCustomization[customizationArrayIndex]
    ) {
      this.item.addOnCustomization[customizationArrayIndex].options.splice(
        optionArrayIndex,
        1,
        option
      );
      return;
    }

    if (
      this.item.requiredCustomization &&
      this.item.requiredCustomization[customizationArrayIndex]
    ) {
      this.item.requiredCustomization[customizationArrayIndex].options.splice(
        optionArrayIndex,
        1,
        option
      );
      return;
    }
  }

  @Mobx.action public addNewEmptyRequiredCustomization() {
    if (!this.item) {
      return;
    }
    this.item.requiredCustomization = [
      ...this.item.requiredCustomization,
      {
        requiredCustomizationId: uuidv4(),
        min: 0,
        max: 0,
        name: "",
        options: []
      } as Model.Restaurant.Menu.Item.CuratedCustomization
    ];
  }

  @Mobx.action public addNewEmptyAddOnCustomization() {
    if (!this.item) {
      return;
    }
    this.item.addOnCustomization = [
      ...this.item.addOnCustomization,
      {
        addOnCustomizationId: uuidv4(),
        min: 0,
        max: 0,
        name: "",
        options: []
      } as Model.Restaurant.Menu.Item.CuratedCustomization
    ];
  }

  @Mobx.action public removeCustomization(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization,
    customizationArrayIndex: number
  ) {
    if (!this.item) {
      return;
    }

    if (
      customization.addOnCustomizationId &&
      this.item.addOnCustomization &&
      this.item.addOnCustomization[customizationArrayIndex]
    ) {
      this.item.addOnCustomization.splice(customizationArrayIndex, 1);
      return;
    }

    if (
      this.item.requiredCustomization &&
      this.item.requiredCustomization[customizationArrayIndex]
    ) {
      this.item.requiredCustomization.splice(customizationArrayIndex, 1);
      return;
    }
  }

  @Mobx.action public setEditedCustomization(
    customization: Model.Restaurant.Menu.Item.CuratedCustomization,
    customizationArrayIndex: number
  ) {
    if (!this.item) {
      return;
    }

    if (
      customization.addOnCustomizationId &&
      this.item.addOnCustomization &&
      this.item.addOnCustomization[customizationArrayIndex]
    ) {
      this.item.addOnCustomization.splice(
        customizationArrayIndex,
        1,
        customization
      );
      return;
    }

    if (
      this.item.requiredCustomization &&
      this.item.requiredCustomization[customizationArrayIndex]
    ) {
      this.item.requiredCustomization.splice(
        customizationArrayIndex,
        1,
        customization
      );
      return;
    }
  }

  // @Mobx.action public subscribe(
  //   menuItemRef: Firebase.firestore.DocumentReference
  // ) {
  //   if (this.isMenuItemSet) {
  //     return;
  //   }

  //   const unsubscribe: () => void = menuItemRef.onSnapshot(
  //     snapshot => {
  //       const menuItem: Model.Restaurant.Menu.Item.Item = {
  //         ...snapshot.data(),
  //         menuItemId: snapshot.id
  //       } as Model.Restaurant.Menu.Item.Item;
  //       this.setMenuItem(menuItem);
  //       unsubscribe();
  //     },
  //     (error: Error) => {
  //       console.error(error);
  //       unsubscribe();
  //     },
  //     () => unsubscribe()
  //   );
  // }
}
