import * as Model from "../../models";
import * as Mobx from "mobx";
import * as Firebase from "firebase/app";
import * as Util from "../../utils";
import "firebase/firestore";

export class Restaurant {
  @Mobx.observable public restaurant?: Model.Restaurant.Restaurant;
  @Mobx.observable public isFetching: boolean = true;

  @Mobx.action public subscribe(
    restaurantRef: Firebase.firestore.DocumentReference
  ) {
    if (
      this.restaurant &&
      this.restaurant.restaurantId &&
      this.restaurant.restaurantId === restaurantRef.id
    ) {
      return;
    }

    // this.rehydrate(restaurantId);

    // const restaurantRef: Firebase.firestore.DocumentReference = this.db
    //   .collection("resturants")
    //   .doc(restaurantId);

    const unsubscribe: () => void = restaurantRef.onSnapshot(
      snapshot => {
        const restaurant: Model.Restaurant.Restaurant = {
          ...snapshot.data(),
          restaurantId: snapshot.id
        } as Model.Restaurant.Restaurant;
        this.restaurant = restaurant;
        this.isFetching = false;
        // this.persist(restaurantId, restaurant);
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }

  @Mobx.computed public get images(): Model.Restaurant.Image[] | undefined {
    if (!this.restaurant) {
      return undefined;
    }

    const images = Util.Curate.Restaurant.images(this.restaurant.images);
    return images;
  }

  @Mobx.computed public get categories():
    | Model.Restaurant.Category[]
    | undefined {
    if (!this.restaurant) {
      return undefined;
    }

    const categories = Util.Curate.Restaurant.categories(
      this.restaurant.categories
    );
    return categories;
  }

  // @Mobx.action private rehydrate(restaurantId: string) {
  //   const serializedRestaurant = localStorage.getItem(
  //     `${restaurantId}-restaurant`
  //   );
  //   if (!serializedRestaurant) {
  //     localStorage.clear();
  //     return;
  //   }

  //   const deserializedRestaurant = JSON.parse(serializedRestaurant);
  //   this.restaurant = deserializedRestaurant;
  // }

  // private persist(
  //   restaurantId: string,
  //   restaurant: Model.Restaurant.Restaurant
  // ) {
  //   localStorage.setItem(
  //     `${restaurantId}-restaurant`,
  //     JSON.stringify(restaurant)
  //   );
  // }
}
