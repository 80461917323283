import * as Mui from "@material-ui/core";

const FixedBottomNavigation = Mui.styled("footer")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  maxWidth: "inherit",
  bottom: 0,
  zIndex: theme.zIndex.mobileStepper + 10
}));

export default FixedBottomNavigation;
