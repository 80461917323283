import * as Mui from "@material-ui/core";

const ExpansionPanelDetailsDensePadding = Mui.styled(Mui.ExpansionPanelDetails)(
  ({ theme }) => ({
    display: "block",
    padding: theme.spacing(2, 2, 1, 2)
  })
);

export default ExpansionPanelDetailsDensePadding;
