import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffMenuItemAddCustomizationOptionListItemButtonProps {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
  customizationArrayIndex: number;
}

const StaffMenuItemAddCustomizationOptionListItemButton: React.FC<StaffMenuItemAddCustomizationOptionListItemButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    function handleClick() {
      return function() {
        menuItemEditStore.addNewEmptyCustomizationOption(
          props.customization,
          props.customizationArrayIndex
        );
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId &&
          menuItemEditStore.isEditable && (
            <>
              <Mui.Divider />
              <Styled.Mui.ListItemNoPadding>
                <Mui.Button color="primary" fullWidth onClick={handleClick()}>
                  Add new customization option
                </Mui.Button>
              </Styled.Mui.ListItemNoPadding>
            </>
          )}
      </>
    );
  }
);

export default StaffMenuItemAddCustomizationOptionListItemButton;
