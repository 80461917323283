import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";
import * as Util from "../../utils";

interface OrderCheckoutButtonProps {}

const OrderCheckoutButton: React.FC<OrderCheckoutButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    return (
      <>
        {!userStore.isStaff &&
          orderStore.order &&
          orderStore.order.items.length > 0 &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              component={Router.Link}
              disabled={
                orderStore.operatingHours &&
                !Util.Restaurant.isOpen(orderStore.operatingHours)
              }
              to={`/order`}
            >
              <Component.TwoLabel
                label1={"Checkout Order"}
                label2={<Component.Price price={orderStore.subTotal} />}
              />
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderCheckoutButton;
