import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as Container from "../../containers";
import * as App from "../../app";
import * as Helper from "../../helpers";

interface MenuCategoryCardProps {
  arrayIndex: number;
  isNew?: boolean;
  expandedCategoryId?: string | false;
  setExpandedCategoryId(categoryId: string | false): void;
  category: Model.Restaurant.Category;
}

const MenuCategoryCard: React.FC<MenuCategoryCardProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );

    function handleCategoryChange(categoryId: string) {
      return function(event: React.ChangeEvent<{}>, isExpanded: boolean) {
        props.setExpandedCategoryId(isExpanded ? categoryId : false);
      };
    }

    const items =
      menuStore.items.get(props.category.categoryId as string) || [];

    const disabled = Helper.Restaurant.Category.isDisabled(
      items.length,
      userStore.isStaff ? true : false
    );

    return (
      <>
        {!disabled && (
          <Mui.ExpansionPanel
            expanded={
              !categoryEditStore.isEditable
                ? props.expandedCategoryId === props.category.categoryId
                : false
            }
            onChange={handleCategoryChange(props.category.categoryId as string)}
            disabled={disabled}
          >
            <Styled.Mui.ExpansionPanelSummary
              expandIcon={
                !categoryEditStore.isEditable ? <Icon.ExpandMore /> : undefined
              }
            >
              <Container.MenuCategoryInfo
                name={props.category.name}
                items={items.length}
              />
              <Container.StaffMenuEditCategoryInfo
                isNew={props.isNew}
                arrayIndex={props.arrayIndex}
                category={props.category}
                items={items.length}
              />
            </Styled.Mui.ExpansionPanelSummary>
            <Styled.Mui.ExpansionPanelDetails>
              <Styled.Mui.ListNoPadding>
                {items.length > 0 &&
                  items.map((item, index) => (
                    <React.Fragment
                      key={`MenuItem#${item.menuItemId}-${index}`}
                    >
                      <Mui.Divider />
                      <Container.MenuItemListItemButton item={item} />
                    </React.Fragment>
                  ))}
                <Container.StaffMenuAddItemListItemButton
                  category={props.category}
                />
              </Styled.Mui.ListNoPadding>
            </Styled.Mui.ExpansionPanelDetails>
          </Mui.ExpansionPanel>
        )}
      </>
    );
  }
);

export default MenuCategoryCard;
