/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as ReactFire from "reactfire";
import * as Router from "react-router-dom";
import * as Styled from "../../styles";
import * as Model from "../../models";
import * as App from "../../app";
import * as Container from "../../containers";
import * as Component from "../../components";

interface OrdersProps {}

const Orders: React.FC<OrdersProps> = Mobx.observer(props => {
  const { restaurantId } = Router.useParams<Model.RouteParams.Orders>();

  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const staffOrdersStore = React.useContext(App.Context.Staff.Order);

  const restaurantRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("restaurants")
    .doc(restaurantId);

  const ordersRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("orders");

  const menuRef = restaurantRef.collection("menu");

  React.useEffect(() => {
    restaurantStore.subscribe(restaurantRef);
    menuStore.subscribe(menuRef, restaurantRef);
    staffOrdersStore.subscribe(ordersRef, restaurantId);
  }, []);

  const orders: Model.Restaurant.Order.Order[] =
    staffOrdersStore[
      `${staffOrdersStore.activeFilter}Orders` as
        | "allOrders"
        | "preparingOrders"
        | "readyOrders"
    ];

  return (
    <>
      {!userStore.isStaff && <Router.Redirect to="/" />}
      {!staffOrdersStore.fetchedData ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Styled.Page.Container>
            {/* <Styled.Page.Title>
              <Styled.Mui.TypographyBold variant="h5">
                My Orders
              </Styled.Mui.TypographyBold>
            </Styled.Page.Title> */}
            <Container.StaffOrdersFilterBar />
            {staffOrdersStore.newOrders.length > 0 && (
              <Container.StaffOrdersNewOrderButton />
            )}
            {orders.map((order, index) => (
              <React.Fragment key={`Order#${order.orderId}-${index}`}>
                <Container.OrderInfoCard order={order} />
              </React.Fragment>
            ))}
          </Styled.Page.Container>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Orders);
