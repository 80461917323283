/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as App from "../../app";

export enum STAFF_BOTTOM_NAVIGATION {
  ORDERS_PAGE,
  HISTORY_PAGE,
  MENU_PAGE
}

interface StaffBottomNavigationProps {}

const StaffBottomNavigation: React.FC<StaffBottomNavigationProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

    const [value, setValue] = React.useState(
      STAFF_BOTTOM_NAVIGATION.ORDERS_PAGE
    );

    const history = Router.useHistory();
    const location = Router.useLocation();

    function navigateTo(route: string) {
      return function() {
        history.push(route);
      };
    }

    function getBottomNavigationValue(pathname: string): number | undefined {
      if (pathname.includes("/orders")) {
        return STAFF_BOTTOM_NAVIGATION.ORDERS_PAGE;
      }
      if (pathname.includes("/history")) {
        return STAFF_BOTTOM_NAVIGATION.HISTORY_PAGE;
      }
      if (pathname.includes("/menu")) {
        return STAFF_BOTTOM_NAVIGATION.MENU_PAGE;
      }
    }

    React.useEffect(() => {
      setValue(getBottomNavigationValue(location.pathname) as number);
    }, []);

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.BottomNavigation
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              showLabels
            >
              <Mui.BottomNavigationAction
                label="Orders"
                icon={<Icon.ListAlt />}
                onClick={navigateTo(
                  `/restaurant/${restaurantStore.restaurant.restaurantId}/orders`
                )}
              />
              <Mui.BottomNavigationAction
                label="History"
                icon={<Icon.History />}
                onClick={navigateTo(
                  `/restaurant/${restaurantStore.restaurant.restaurantId}/history`
                )}
              />
              <Mui.BottomNavigationAction
                label="Menu"
                icon={<Icon.LocalDining />}
                onClick={navigateTo(
                  `/restaurant/${restaurantStore.restaurant.restaurantId}/menu`
                )}
              />
            </Mui.BottomNavigation>
          )}
      </>
    );
  }
);

export default StaffBottomNavigation;
