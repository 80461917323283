import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";

interface PriceProps {
  price: number;
}

const Price: React.FC<PriceProps> = Mobx.observer(props => {
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);

  const currency = (restaurantStore.restaurant &&
    restaurantStore.restaurant.currency) || {
    code: "INR",
    symbol: "₹"
  };
  return (
    <>
      {currency.symbol} {props.price.toFixed(2)}
    </>
  );
});

export default Price;
