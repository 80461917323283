import * as Model from "../../../models";
import * as Mobx from "mobx";
import uuidv4 from "uuid/v4";
import "firebase/firestore";

export class Edit {
  @Mobx.observable public isEditable?: boolean;
  private originalCategories: Model.Restaurant.Category[] = [];
  @Mobx.observable public categories: Model.Restaurant.Category[] = [];
  // @Mobx.observable public editedCategories: Map<
  //   string,
  //   Model.Restaurant.Category
  // > = new Map();

  @Mobx.action public reset() {
    this.setIsEditable();
    this.originalCategories = [];
    this.categories = [];
    // this.editedCategories = new Map();
  }

  // @Mobx.action public removeNonEditedCategory(
  //   category: Model.Restaurant.Category,
  // ) {
  //   this.editedCategories.delete(category.categoryId as string);
  // }

  // @Mobx.action public addEditedCategory(
  //   category: Model.Restaurant.Category,
  // ) {
  //   this.editedCategories.set(category.categoryId as string, category);
  // }

  @Mobx.computed
  public get asRestaurantCategories(): Model.Restaurant.Categories {
    return [...this.originalCategories, ...this.categories]
      .filter(category => category.name)
      .reduce(
        (
          categories: Model.Restaurant.Categories,
          category: Model.Restaurant.Category
        ) => {
          const { categoryId, ...restOfCategory } = category;
          return { ...categories, [categoryId as string]: restOfCategory };
        },
        {} as Model.Restaurant.Categories
      );
  }

  @Mobx.action public setEditedCategory(
    category: Model.Restaurant.Category,
    arrayIndex: number
  ) {
    this.categories.splice(arrayIndex, 1, category);
  }

  @Mobx.action public setEditedOriginalCategory(
    category: Model.Restaurant.Category,
    arrayIndex: number
  ) {
    this.originalCategories.splice(arrayIndex, 1, category);
  }

  @Mobx.action public setIsEditable(isEditable?: boolean) {
    this.isEditable = isEditable;
  }

  @Mobx.action public setOriginalCategories(
    categories: Model.Restaurant.Category[]
  ) {
    this.originalCategories = categories;
  }

  @Mobx.action public addNewEmptyCategory() {
    this.categories.push({
      categoryId: uuidv4(),
      displayOrder: 0,
      name: ""
    } as Model.Restaurant.Category);
  }
}
