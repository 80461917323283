import * as Model from "../../../../../../models";

export function isDisabled({
  customization,
  isChecked,
  isOnlyOneOptionAllowed,
  checkedOptions
}: {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
  isChecked: boolean;
  isOnlyOneOptionAllowed?: boolean;
  checkedOptions?: Map<string, Model.Restaurant.Menu.Item.Customization.Option>;
}): boolean {
  return !isOnlyOneOptionAllowed &&
    !isChecked &&
    checkedOptions &&
    checkedOptions.size === customization.max
    ? true
    : false;
}
