import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffMenuEditButtonProps {}

const StaffMenuEditButton: React.FC<StaffMenuEditButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );
    const menuEditStore = React.useContext(App.Context.Restaurant.Menu.Edit);

    function handleClick(categories: Model.Restaurant.Category[]) {
      return function() {
        menuEditStore.setIsEditable(true);
        categoryEditStore.setIsEditable(true);
        categoryEditStore.setOriginalCategories(categories);
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.categories &&
          (!categoryEditStore.isEditable || !menuEditStore.isEditable) && (
            <Mui.Button
              onClick={handleClick(restaurantStore.categories)}
              color="primary"
              variant="contained"
              fullWidth
            >
              Edit Categories
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffMenuEditButton;
