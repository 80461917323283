import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface RestaurantsAppBarTitleProps {}

const RestaurantsAppBarTitle: React.FC<RestaurantsAppBarTitleProps> = Mobx.observer(
  props => {
    const mallStore = React.useContext(App.Context.Mall.Mall);

    const theme = Mui.useTheme();
    const { pathname } = Router.useLocation();
    const isRestaurantsPage = pathname.includes("/mall/");

    return (
      <>
        {isRestaurantsPage && mallStore.activeMall && (
          <>
            <Mui.Typography variant="h6">
              {mallStore.activeMall.name}
            </Mui.Typography>
            <Mui.Typography
              variant="body2"
              style={{ marginTop: theme.spacing(-1) }}
            >
              {mallStore.activeMall.description}
            </Mui.Typography>
          </>
        )}
      </>
    );
  }
);

export default RestaurantsAppBarTitle;
