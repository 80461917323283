import * as React from "react";
import * as Icon from "@material-ui/icons";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";

interface CounterProps {
  max?: number;
  min?: number;
  step?: number;
  counter: number;
  setCounter(counter: number): void;
}

const Counter: React.FC<CounterProps> = props => {
  function incrementCounter() {
    props.setCounter((props.counter as number) + (props.step as number));
  }

  function decrementCounter() {
    props.setCounter((props.counter as number) - (props.step as number));
  }

  return (
    <>
      <Styled.Mui.ButtonGroup size="large" fullWidth>
        <Mui.Button
          disabled={props.counter <= (props.min as number)}
          onClick={decrementCounter}
        >
          <Icon.RemoveCircle />
        </Mui.Button>
        <Mui.Button disabled={true}>
          <Styled.Mui.TypographyBold variant="h5" color="secondary">
            {props.counter}
          </Styled.Mui.TypographyBold>
        </Mui.Button>
        <Mui.Button
          disabled={props.counter >= (props.max as number)}
          onClick={incrementCounter}
        >
          <Icon.AddCircle />
        </Mui.Button>
      </Styled.Mui.ButtonGroup>
    </>
  );
};

Counter.defaultProps = {
  max: 10,
  min: 1,
  step: 1
};

export default Counter;
