import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Model from "../../models";
import * as Component from "../../components";
import * as Container from "../../containers";

interface OrderInfoCardProps {
  order: Model.Restaurant.Order.Order;
}

const OrderInfoCard: React.FC<OrderInfoCardProps> = Mobx.observer(props => {
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  function handleClick(order: Model.Restaurant.Order.Order) {
    return function() {
      orderStore.order = order;
    };
  }

  return (
    <>
      <Mui.ExpansionPanel expanded={true}>
        <Mui.CardActionArea
          component={Router.Link}
          to={`/order`}
          onClick={handleClick(props.order)}
        >
          <Styled.Mui.ExpansionPanelSummary>
            <div
              style={{
                marginLeft: -16
              }}
            >
              <Component.OrderBadge
                status={
                  props.order.cancelled
                    ? "cancelled"
                    : (props.order.status as Model.Restaurant.Order.Status)
                }
              />
            </div>
            {props.order.acceptedAt &&
              props.order.orderTime &&
              props.order.statusUpdates &&
              props.order.statusUpdates.accepted &&
              !props.order.statusUpdates.ready && (
                <Component.Timer
                  duration={props.order.orderTime}
                  startTimestamp={props.order.acceptedAt.toDate().getTime()}
                />
              )}
          </Styled.Mui.ExpansionPanelSummary>
          <Styled.Mui.ExpansionPanelDetails style={{ marginTop: -20 }}>
            <Styled.Mui.ListNoPadding>
              <Mui.ListItem>
                <Component.OrderInfo order={props.order} />
              </Mui.ListItem>
            </Styled.Mui.ListNoPadding>
          </Styled.Mui.ExpansionPanelDetails>
        </Mui.CardActionArea>
        <Styled.Mui.ListNoPadding>
          <Container.StaffOrderReadyListItemButton order={props.order} />
          <Container.StaffOrderCloseListItemButton order={props.order} />
        </Styled.Mui.ListNoPadding>
      </Mui.ExpansionPanel>
    </>
  );
});

export default OrderInfoCard;
