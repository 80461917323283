import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Model from "../../models";
import * as Mobx from "mobx-react-lite";

interface MenuItemUpdateOrderButtonProps {}

const MenuItemUpdateOrderButton: React.FC<MenuItemUpdateOrderButtonProps> = Mobx.observer(
  props => {
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    const history = Router.useHistory();

    function handleClick(
      mallAndRestaurantDetails: Model.Restaurant.Order.MallDetails &
        Model.Restaurant.Order.RestaurantDetails
    ) {
      return function() {
        try {
          menuItemStore.validateRequiredCustomizations();
          orderStore.init(mallAndRestaurantDetails);
          orderStore.addItem(menuItemStore.asOrderItem);

          history.push(`/order`);
          toastStore.showToast({
            body: "Item updated in Order"
          });
        } catch (error) {
          console.error(error);

          toastStore.showToast({
            ...((error as Error).name && { title: (error as Error).name }),
            body: (error as Error).message
          });
        }
      };
    }

    return (
      <>
        {menuItemStore.menuItem &&
          orderStore.editItem &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClick({
                mallId: restaurantStore.restaurant.mallId,
                mallName: restaurantStore.restaurant.mallName,
                restaurantAddress: restaurantStore.restaurant.address,
                restaurantId: restaurantStore.restaurant.restaurantId,
                restaurantName: restaurantStore.restaurant.name,
                operatingHours: restaurantStore.restaurant.operatingHours
              })}
            >
              <Component.TwoLabel
                label1={"Update Order"}
                label2={<Component.Price price={menuItemStore.itemTotal} />}
              />
            </Mui.Button>
          )}
      </>
    );
  }
);

export default MenuItemUpdateOrderButton;
