import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface OrderAddMoreListItemButtonProps {}

const OrderAddMoreListItemButton: React.FC<OrderAddMoreListItemButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const orderStore = React.useContext(App.Context.Restaurant.Order);

    return (
      <>
        {!userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId &&
          !orderStore.isOrderCreating &&
          orderStore.isOrderEditable && (
            <>
              <Mui.Divider />
              <Mui.ListItem
                button
                component={Router.Link}
                to={`/restaurant/${restaurantStore.restaurant.restaurantId}/menu`}
              >
                <Mui.ListItemText
                  primary={
                    <>
                      <Mui.Typography color="secondary" variant="body2">
                        Add more items
                      </Mui.Typography>
                    </>
                  }
                />
              </Mui.ListItem>
            </>
          )}
      </>
    );
  }
);

export default OrderAddMoreListItemButton;
