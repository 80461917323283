import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as App from "../../app";
import * as Component from "../../components";

interface MenuItemListItemButtonProps {
  item: Model.Restaurant.Menu.Item.Item;
}

const MenuItemListItemButton: React.FC<MenuItemListItemButtonProps> = Mobx.observer(props => {
  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuItemStore = React.useContext(App.Context.Restaurant.Menu.Item.Item);

  function handleListButtonClick(item: Model.Restaurant.Menu.Item.Item) {
    return function() {
      menuItemStore.setMenuItem(item);
    };
  }

  const isItemAvailable = () => {
    if (userStore.isStaff) {
      return true;
    }
    return props.item.available;
  };

  return (
    <>
      {restaurantStore.restaurant && restaurantStore.restaurant.restaurantId && props.item.menuItemId && (
        <Mui.ListItem
          button
          onClick={handleListButtonClick(props.item)}
          component={Router.Link}
          to={`/restaurant/${restaurantStore.restaurant.restaurantId}/item/${props.item.menuItemId}`}
          disabled={!isItemAvailable()}
        >
          <Component.ItemInfo
            available={props.item.available}
            name={props.item.name}
            price={props.item.price}
            description={props.item.description}
            tags={props.item.tags}
          />
        </Mui.ListItem>
      )}
    </>
  );
});

export default MenuItemListItemButton;
