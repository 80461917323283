import * as Model from "../../models";

const days: Model.Restaurant.Days[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export function isOpen(
  operatingHours: Model.Restaurant.OperatingHours
): boolean {
  if (!operatingHours) {
    return false;
  }

  const todaysDay = new Date().getDay();
  const timings = operatingHours[days[todaysDay]];

  const now = new Date();

  const isOpen = timings
    ? timings
        .map(timing => {
          const [
            startHours,
            startMinutes,
            startSeconds
          ] = timing.startTime.split(":");
          const [endHours, endMinutes, endSeconds] = timing.endTime.split(":");
          return {
            startTime: new Date(
              new Date().setHours(
                parseInt(startHours, 10),
                parseInt(startMinutes, 10),
                parseInt(startSeconds, 10)
              )
            ),
            endTime: new Date(
              new Date().setHours(
                parseInt(endHours, 10),
                parseInt(endMinutes, 10),
                parseInt(endSeconds, 10)
              )
            )
          };
        })
        .some(timing => now >= timing.startTime && now <= timing.endTime)
    : false;

  return isOpen;
}
