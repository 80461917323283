import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as App from "../../app";
import * as Model from "../../models";

interface StaffMenuAddItemListItemButtonProps {
  category: Model.Restaurant.Category;
}

const StaffMenuAddItemListItemButton: React.FC<StaffMenuAddItemListItemButtonProps> = props => {
  const userStore = React.useContext(App.Context.User.User);
  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const menuItemEditStore = React.useContext(
    App.Context.Restaurant.Menu.Item.Edit
  );

  const history = Router.useHistory();

  function handleClick(restaurantId: string) {
    return function() {
      menuItemEditStore.setIsEditable(true);
      menuItemEditStore.addNewEmptyItem(props.category.categoryId as string);

      if (!menuItemEditStore.item) {
        return;
      }

      history.push(
        `/restaurant/${restaurantId}/item/${menuItemEditStore.item
          .menuItemId as string}/add`
      );
    };
  }

  return (
    <>
      {userStore.isStaff &&
        restaurantStore.restaurant &&
        restaurantStore.restaurant.restaurantId && (
          <>
            <Mui.Divider />
            <Styled.Mui.ListItemNoPadding>
              <Mui.Button
                color="primary"
                fullWidth
                onClick={handleClick(restaurantStore.restaurant.restaurantId)}
              >
                Add new menu item
              </Mui.Button>
            </Styled.Mui.ListItemNoPadding>
          </>
        )}
    </>
  );
};

export default StaffMenuAddItemListItemButton;
