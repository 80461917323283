import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Model from "../../models";
// import * as App from "../../app";
import * as Container from "../../containers";

// TODO: Experiment : Remove it
// import * as Formik from "formik";
// import * as FormikMui from "formik-material-ui";
// import * as Yup from "yup";
// import * as Component from "../../components";

interface MenuItemCustomizationCardProps {
  customization: Model.Restaurant.Menu.Item.CuratedCustomization;
  arrayIndex: number;
}

const MenuItemCustomizationCard: React.FC<MenuItemCustomizationCardProps> = Mobx.observer(
  props => {
    return (
      <>
        <Mui.ExpansionPanel expanded={true}>
          <Styled.Mui.ExpansionPanelSummary>
            <Container.MenuItemCustomizationInfo
              customization={props.customization}
            />
            <Container.StaffMenuItemCustomizationInfo
              customization={props.customization}
            />
            <Container.StaffMenuItemEditCustomizationInfo
              customization={props.customization}
              customizationArrayIndex={props.arrayIndex}
            />
          </Styled.Mui.ExpansionPanelSummary>
          <Styled.Mui.ExpansionPanelDetails>
            <Styled.Mui.ListNoPadding dense>
              {props.customization.options.map((option, index) => (
                <React.Fragment
                  key={`CustomizationOption#${index}-${option.optionId}`}
                >
                  <Mui.Divider />
                  <Container.MenuItemCustomizationOptionListItemButton
                    option={option}
                    customization={props.customization}
                  />
                  <Container.StaffMenuItemCustomizationOptionListItem
                    option={option}
                  />
                  <Container.StaffMenuItemEditCustomizationOptionListItem
                    customization={props.customization}
                    customizationArrayIndex={props.arrayIndex}
                    option={option}
                    arrayIndex={index}
                  />
                </React.Fragment>
              ))}
              <Container.StaffMenuItemAddCustomizationOptionListItemButton
                customization={props.customization}
                customizationArrayIndex={props.arrayIndex}
              />
            </Styled.Mui.ListNoPadding>
          </Styled.Mui.ExpansionPanelDetails>
          {/* <Container.StaffMenuItemEditCustomizationActions
            customization={props.customization}
          /> */}
        </Mui.ExpansionPanel>
        {/* TODO: Experiment : Remove it */}
        {/* <Formik.Formik
          validateOnChange
          validationSchema={Yup.object<{ num: number | undefined }>({
            num: Yup.number()
              .positive("Cant be negative")
              .notRequired()
          })}
          initialValues={{ num: "" }}
          onSubmit={(values, { setSubmitting }) => {
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {({}) => (
            <Formik.Form>
              <Formik.Field
                component={Component.FormikMui.TextField.AutoSubmitting}
                type="number"
                name="num"
                fullWidth
                label="Experiment : Remove it"
              />
            </Formik.Form>
          )}
        </Formik.Formik> */}
      </>
    );
  }
);

export default MenuItemCustomizationCard;
