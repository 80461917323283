import * as Model from "../../../../models";
import * as Mobx from "mobx";
import * as Helper from "../../../../helpers";
import * as Firebase from "firebase/app";
import * as Util from "../../../../utils";
import "firebase/firestore";

export class Item {
  @Mobx.observable public menuItem?: Model.Restaurant.Menu.CuratedItem;
  @Mobx.observable public quantityCounter: number = 1;
  @Mobx.observable public instruction: string = "";
  @Mobx.observable public customizations: Map<
    string,
    Map<string, Model.Restaurant.Menu.Item.Customization.Option>
  > = new Map();
  @Mobx.observable public isFetching: boolean = true;

  constructor() {
    this.setQuantityCounter = this.setQuantityCounter.bind(this);
    this.setInstruction = this.setInstruction.bind(this);
    this.setCustomizationOptionChecked = this.setCustomizationOptionChecked.bind(this);
    this.validateRequiredCustomizations = this.validateRequiredCustomizations.bind(this);
  }

  @Mobx.action public toggleAvailability() {
    if (!this.menuItem) {
      return;
    }

    this.menuItem.available = !this.menuItem.available;
  }

  @Mobx.computed public get itemTotal() {
    const itemCustomizationsTotal = Array.from(this.customizations.values()).reduce(
      (itemCustomizationsTotal: number, customization) => {
        return (
          itemCustomizationsTotal +
          Array.from(customization.values()).reduce((perCustomizationTotal: number, option) => {
            return perCustomizationTotal + (option.price || 0);
          }, 0)
        );
      },
      0
    );

    if (!this.menuItem) {
      return itemCustomizationsTotal;
    }

    return (this.menuItem.price + itemCustomizationsTotal) * this.quantityCounter;
  }

  @Mobx.action public setCustomizations(
    customizations?: Map<string, Map<string, Model.Restaurant.Menu.Item.Customization.Option>>
  ) {
    if (!customizations) {
      return;
    }

    this.customizations = customizations;
  }

  @Mobx.action public setCustomizationOptionChecked(
    option: Model.Restaurant.Menu.Item.Customization.Option,
    customization: Model.Restaurant.Menu.Item.CuratedCustomization
  ) {
    const checkedOptionsAlreadyExist:
      | Map<string, Model.Restaurant.Menu.Item.Customization.Option>
      | undefined = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(customization, this.customizations);

    if (Helper.Restaurant.Menu.Item.Customization.isOnlyOneOptionAllowed(customization) && checkedOptionsAlreadyExist) {
      checkedOptionsAlreadyExist.clear();
    }

    const isOptionChecked = Helper.Restaurant.Menu.Item.Customization.Option.toggleChecked(
      Helper.Restaurant.Menu.Item.Customization.Option.isChecked(option, checkedOptionsAlreadyExist)
    );

    const customizationId: string = (customization.requiredCustomizationId ||
      customization.addOnCustomizationId) as string;

    if (isOptionChecked && !checkedOptionsAlreadyExist) {
      const checkedOptions: Map<string, Model.Restaurant.Menu.Item.Customization.Option> = new Map();
      checkedOptions.set(
        option.optionId as string,
        {
          ...option,
          ...(customization.addOnCustomizationId && {
            addOnCustomizationId: customization.addOnCustomizationId
          }),
          ...(customization.requiredCustomizationId && {
            requiredCustomizationId: customization.requiredCustomizationId
          })
        } as Model.Restaurant.Order.Item.Option
      );
      this.customizations.set(customizationId, checkedOptions);
      return;
    }

    if (isOptionChecked && checkedOptionsAlreadyExist) {
      checkedOptionsAlreadyExist.set(
        option.optionId as string,
        {
          ...option,
          ...(customization.addOnCustomizationId && {
            addOnCustomizationId: customization.addOnCustomizationId
          }),
          ...(customization.requiredCustomizationId && {
            requiredCustomizationId: customization.requiredCustomizationId
          })
        } as Model.Restaurant.Order.Item.Option
      );
      this.customizations.set(customizationId, checkedOptionsAlreadyExist);
      return;
    }

    if (!isOptionChecked && checkedOptionsAlreadyExist) {
      checkedOptionsAlreadyExist.delete(option.optionId as string);
      this.customizations.set(customizationId, checkedOptionsAlreadyExist);
      return;
    }
  }

  @Mobx.action public validateRequiredCustomizations() {
    if (!this.menuItem) {
      return;
    }

    if (!this.menuItem.requiredCustomization) {
      return;
    }

    this.menuItem.requiredCustomization.forEach(customization => {
      const checkedOptionsAlreadyExist:
        | Map<string, Model.Restaurant.Menu.Item.Customization.Option>
        | undefined = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(customization, this.customizations);

      if (!checkedOptionsAlreadyExist) {
        throw new Error(`${customization.name} info is required before adding.`);
      }

      if (checkedOptionsAlreadyExist && customization.min && customization.min !== checkedOptionsAlreadyExist.size) {
        throw new Error(`You need to pick at least ${customization.min} options for ${customization.name}`);
      }
    });
  }

  @Mobx.computed public get asOrderItem(): Model.Restaurant.Order.Item.Item | undefined {
    if (!this.menuItem || !this.quantityCounter) {
      return undefined;
    }

    const addOnOptions =
      this.menuItem.addOnCustomization &&
      this.menuItem.addOnCustomization
        .map(customization => {
          const checkedOptionsAlreadyExist:
            | Map<string, Model.Restaurant.Menu.Item.Customization.Option>
            | undefined = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(
            customization,
            this.customizations
          );

          if (!checkedOptionsAlreadyExist) {
            return undefined;
          }

          return Array.from(checkedOptionsAlreadyExist.values());
        })
        .filter(options => options)
        .reduce((addOn: Model.Restaurant.Order.Item.Option[], options) => {
          return [...addOn, ...options];
        }, []);

    const requiredOptions =
      this.menuItem.requiredCustomization &&
      this.menuItem.requiredCustomization
        .map(customization => {
          const checkedOptionsAlreadyExist:
            | Map<string, Model.Restaurant.Menu.Item.Customization.Option>
            | undefined = Helper.Restaurant.Menu.Item.Customization.getCheckedOptions(
            customization,
            this.customizations
          );

          if (!checkedOptionsAlreadyExist) {
            return undefined;
          }

          return Array.from(checkedOptionsAlreadyExist.values());
        })
        .filter(options => options)
        .reduce((addOn: Model.Restaurant.Order.Item.Option[], options) => {
          return [...addOn, ...options];
        }, []);

    const orderItem: Model.Restaurant.Order.Item.Item = {
      ...(this.instruction && { instruction: this.instruction }),
      orderItemId: this.menuItem.menuItemId as string,
      name: this.menuItem.name,
      price: this.menuItem.price,
      quantity: this.quantityCounter,
      total: this.itemTotal,
      tags: this.menuItem.tags,
      ...(addOnOptions && { addOn: addOnOptions }),
      ...(requiredOptions && { required: requiredOptions })
    };

    return orderItem;
  }

  @Mobx.action public reset() {
    this.quantityCounter = 1;
    this.instruction = "";
    this.customizations = new Map();
    this.menuItem = undefined;
  }

  @Mobx.action public setMenuItem(menuItem: Model.Restaurant.Menu.Item.Item) {
    const curatedMenuItem = Util.Curate.Restaurant.Menu.Item.item(menuItem);
    this.menuItem = curatedMenuItem;
    this.isFetching = false;
  }

  @Mobx.action public setQuantityCounter(counter: number) {
    this.quantityCounter = counter;
  }

  @Mobx.action public setInstruction(instruction: string) {
    this.instruction = instruction;
  }

  @Mobx.action public subscribe(menuItemRef: Firebase.firestore.DocumentReference) {
    if (this.menuItem && this.menuItem.menuItemId && this.menuItem.menuItemId === menuItemRef.id) {
      return;
    }

    const unsubscribe: () => void = menuItemRef.onSnapshot(
      snapshot => {
        const menuItem: Model.Restaurant.Menu.Item.Item = {
          ...snapshot.data(),
          menuItemId: snapshot.id
        } as Model.Restaurant.Menu.Item.Item;
        this.setMenuItem(menuItem);
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }
}
