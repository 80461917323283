import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

export class Mall {
  @Mobx.observable public activeMall?: Model.Mall;
  @Mobx.observable public malls: Model.Mall[] = [];
  @Mobx.observable public cities: string[] = [];
  @Mobx.observable public isFetching: boolean = true;
  @Mobx.observable public activeCity?: string;

  constructor() {
    const citiesRef = Firebase.firestore().collection("cities");
    this.getCities(citiesRef);
  }

  @Mobx.action public async getCities(
    citiesRef: Firebase.firestore.CollectionReference
  ) {
    const citiesSnapshot = await citiesRef.get();
    this.cities = citiesSnapshot.docs.map(doc => doc.id);
  }

  @Mobx.action public setActiveMall(mall: Model.Mall) {
    this.activeMall = mall;
  }

  @Mobx.action public setActiveCity(city: string) {
    this.activeCity = city;
  }

  @Mobx.action public subscribe(
    mallsRef: Firebase.firestore.CollectionReference,
    city: string
  ) {
    if (this.activeCity === city) {
      return;
    }

    this.setActiveCity(city);

    const unsubscribe: () => void = mallsRef
      .where("city", "==", this.activeCity)
      .onSnapshot(
        snapshot => {
          const malls: Model.Mall[] = snapshot.docs.map(doc => {
            return {
              mallId: doc.id,
              ...doc.data()
            } as Model.Mall;
          });
          this.malls = malls;
          this.isFetching = false;
        },
        (error: Error) => {
          console.error(error);
          unsubscribe();
        },
        () => unsubscribe()
      );
  }
}
