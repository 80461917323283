import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import "firebase/auth";

export class Account {
  @Mobx.observable public pastOrders: Model.Restaurant.Order.Order[] = [];
  @Mobx.observable public isFetching: boolean = true;

  constructor() {
    Firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        return;
      }

      const ordersRef = Firebase.firestore().collection("orders");
      this.subscribeToPastOrders(ordersRef, user.uid);
    });
  }

  @Mobx.action public subscribeToPastOrders(
    ordersRef: Firebase.firestore.CollectionReference,
    userId: string
  ) {
    const unsubscribe: () => void = ordersRef
      .where("userId", "==", userId)
      .where("cancelled", "==", false)
      .orderBy("createdAt", "asc")
      .onSnapshot(
        snapshot => {
          const orders: Model.Restaurant.Order.Order[] = snapshot.docs.map(
            doc =>
              ({
                orderId: doc.id,
                ...doc.data()
              } as Model.Restaurant.Order.Order)
          );
          this.pastOrders = orders;
          this.isFetching = false;
        },
        (error: Error) => {
          console.error(error);
          unsubscribe();
        },
        () => unsubscribe()
      );
  }
}
