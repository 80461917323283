import * as Model from "../../../models";
import * as Mobx from "mobx";
import * as Firebase from "firebase/app";
import * as Util from "../../../utils";
import "firebase/firestore";

export class Menu {
  @Mobx.observable public expandedCategoryId?: string | false;

  @Mobx.observable public items: Map<
    string,
    Model.Restaurant.Menu.Item.Item[]
  > = new Map();

  @Mobx.observable public isFetching: boolean = true;
  @Mobx.observable public restaurantId: string = "";

  constructor() {
    this.setExpandedCategoryId = this.setExpandedCategoryId.bind(this);
  }

  @Mobx.action public setExpandedCategoryId(
    expandedCategoryId: string | false
  ) {
    this.expandedCategoryId = expandedCategoryId;
  }

  @Mobx.action public subscribe(
    menuRef: Firebase.firestore.CollectionReference,
    restaurantRef: Firebase.firestore.DocumentReference
  ) {
    if (this.items.size && this.restaurantId === restaurantRef.id) {
      return;
    }

    // this.rehydrate(restaurantId);

    // const menuRef: Firebase.firestore.CollectionReference = Firebase.firestore()
    //   .collection("resturants")
    //   .doc(restaurantId)
    //   .collection("menu");
    this.restaurantId = restaurantRef.id;
    this.isFetching = true;
    const unsubscribe: () => void = menuRef.onSnapshot(
      snapshot => {
        const menu: Model.Restaurant.Menu.Item.Item[] = snapshot.docs.map(
          doc =>
            ({
              ...doc.data(),
              menuItemId: doc.id
            } as Model.Restaurant.Menu.Item.Item)
        );
        const menuItems = Util.Curate.Restaurant.Menu.menu(menu);
        this.items = menuItems;
        this.isFetching = false;
        // this.persist(restaurantId, menu);
      },
      (error: Error) => {
        console.error(error);
        unsubscribe();
      },
      () => unsubscribe()
    );
  }

  // @Mobx.action private rehydrate(restaurantId: string) {
  //   const serializedMenu = localStorage.getItem(`${restaurantId}-menu`);
  //   if (!serializedMenu) {
  //     localStorage.clear();
  //     return;
  //   }

  //   const deserializedMenu = JSON.parse(serializedMenu);
  //   const menuMap = Util.Curate.Restaurant.Menu.menu(deserializedMenu);
  //   this.menuMap = menuMap;
  // }

  // private persist(restaurantId: string, menu: Model.Restaurant.Menu.Item.Item[]) {
  //   localStorage.setItem(`${restaurantId}-menu`, JSON.stringify(menu));
  // }
}
