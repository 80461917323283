import * as Mobx from "mobx";
import * as Model from "../../models";
import * as Helper from "../../helpers";
import * as Firebase from "firebase/app";
import "firebase/auth";
import * as Yup from "yup";

export class User {
  @Mobx.observable public user?: Model.User | Model.Staff;
  @Mobx.observable public isStaff?: boolean;
  @Mobx.observable public activeUserEmail?: string;
  private unsubscribe?: () => void;

  constructor() {
    Firebase.auth().onAuthStateChanged(async user => {
      if (!user || user.isAnonymous) {
        return;
      }

      const isStaff = await Helper.User.isStaff(user);
      this.isStaff = isStaff;

      if (this.unsubscribe) {
        this.unsubscribe();
      }

      try {
        const userRef = Firebase.firestore()
          .collection(isStaff ? "staffs" : "users")
          .doc(user.uid);
        const userSnapshot = await userRef.get();

        if (!isStaff && userSnapshot.exists && this.activeUserEmail) {
          userRef.update({ email: this.activeUserEmail });
        } else if (!isStaff && this.activeUserEmail) {
          userRef.set({ email: this.activeUserEmail });
        }

        this.subscribe(userRef);
      } catch (error) {
        console.error(error);
      }
    });
  }

  @Mobx.action public setEmail(email: string) {
    const schema = Yup.object().shape({
      email: Yup.string().email()
    });

    if (!schema.isValid({ email })) {
      console.error(new Error("Email provided is not valid."));
      return;
    }

    this.activeUserEmail = email;
  }

  @Mobx.action public subscribe(userRef: Firebase.firestore.DocumentReference) {
    if (this.user && this.user.userId && this.user.userId === userRef.id) {
      return;
    }

    this.unsubscribe = userRef.onSnapshot(
      snapshot => {
        const user: Model.User | Model.Staff = snapshot.data() as
          | Model.User
          | Model.Staff;
        this.user = user;
      },
      (error: Error) => {
        console.error(error);
        if (this.unsubscribe) {
          this.unsubscribe();
        }
      },
      () => this.unsubscribe && this.unsubscribe()
    );
  }
}
