/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Router from "react-router-dom";
import * as ReactFire from "reactfire";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";
import "firebase/firestore";

interface StaffMenuItemEditSaveButtonProps {}

const StaffMenuItemEditSaveButton: React.FC<StaffMenuItemEditSaveButtonProps> = Mobx.observer(
  props => {
    const firebaseApp = ReactFire.useFirebaseApp();
    const restaurantsRef = firebaseApp.firestore().collection("restaurants");

    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuItemEditStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );
    const toastStore = React.useContext(App.Context.Toast);

    const history = Router.useHistory();
    const location = Router.useLocation();

    React.useEffect(() => {
      return () => {
        menuItemEditStore.reset();
      };
    }, []);

    function handleClick(restaurantId: string, isNew?: boolean) {
      return async function() {
        try {
          const item: Model.Restaurant.Menu.Item.Item =
            menuItemEditStore.asRestaurantMenuItem;

          const menuRef = restaurantsRef.doc(restaurantId).collection("menu");

          if (isNew) {
            await menuRef.add(item);
            history.push(`/restaurant/${restaurantId}/menu`);
            toastStore.showToast({ body: "Menu item successfully added." });
          } else {
            await menuRef.doc(item.menuItemId as string).update(item);
            history.push(`/restaurant/${restaurantId}/item/${item.menuItemId}`);
            toastStore.showToast({ body: "Menu item successfully updated." });
          }
        } catch (error) {
          console.error(error);
          toastStore.showToast({ body: error.message });
        }
      };
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          restaurantStore.restaurant.restaurantId &&
          menuItemEditStore.isEditable && (
            <Mui.Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleClick(
                restaurantStore.restaurant.restaurantId,
                location.pathname.includes("/add") ? true : false
              )}
            >
              Save
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffMenuItemEditSaveButton;
