import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface OrderRetryCashfreePaymentButtonProps {
  disabled: boolean;
}

const OrderRetryCashfreePaymentButton: React.FC<OrderRetryCashfreePaymentButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const orderStore = React.useContext(App.Context.Restaurant.Order);
    const toastStore = React.useContext(App.Context.Toast);

    function handleRetryCashfreePaymentClick() {
      return async function() {
        await orderStore.processCashfreePayment(toastStore);
      };
    }

    return (
      <>
        {!userStore.isStaff &&
          !orderStore.isOrderEditable &&
          orderStore.order &&
          orderStore.order.paymentStatus !== "captured" &&
          orderStore.order.paymentStatus !== "paid" && (
            <Mui.Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={props.disabled}
              onClick={handleRetryCashfreePaymentClick()}
            >
              <Component.TwoLabel
                label1="Retry Payment"
                label2={<Component.Price price={orderStore.total} />}
              ></Component.TwoLabel>
            </Mui.Button>
          )}
      </>
    );
  }
);

export default OrderRetryCashfreePaymentButton;
