import * as Firebase from "firebase/app";

export async function isStaff(user: Firebase.User | null): Promise<boolean> {
  if (!user) {
    return false;
  }

  const token = await user.getIdTokenResult();
  return token.claims.staff ? true : false;
}
