import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Mobx from "mobx-react-lite";
import * as App from "../../app";
import * as Icon from "@material-ui/icons";
import * as Container from "../../containers";

export enum VARIANT {
  MASTER = "MASTER",
  SLAVE = "SLAVE",
  DIALOG = "DIALOG"
}

interface MainProps {
  variant?: VARIANT;
}

const Main: React.FC<MainProps> = Mobx.observer(props => {
  const toastStore = React.useContext(App.Context.Toast);

  function handleClose(
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    toastStore.hideToast();
  }

  return (
    <>
      <Styled.Mui.ContainerNoPadding maxWidth="sm">
        <Mui.Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={toastStore.toast ? true : false}
          autoHideDuration={6000}
          onClose={handleClose}
          message={toastStore.toast && toastStore.toast.body}
          action={
            <Mui.IconButton color="inherit" onClick={handleClose}>
              <Icon.Close />
            </Mui.IconButton>
          }
        />
        {props.variant !== VARIANT.DIALOG && <Container.AppBar />}
        {props.children}
        {props.variant === VARIANT.MASTER && (
          <Styled.Mui.ContainerPaddingBottom maxWidth="sm">
            <Styled.Footer.FixedBottomNavigation>
              <Container.BottomNavigation />
              <Container.StaffBottomNavigation />
            </Styled.Footer.FixedBottomNavigation>
          </Styled.Mui.ContainerPaddingBottom>
        )}
      </Styled.Mui.ContainerNoPadding>
    </>
  );
});

Main.defaultProps = {
  variant: VARIANT.SLAVE
};

export default Main;
