import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as App from "../../app";
import * as Model from "../../models";
import * as Firebase from "firebase/app";
import "firebase/auth";

interface AccountAppBarLogoutButtonProps {}

const AccountAppBarLogoutButton: React.FC<AccountAppBarLogoutButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);

    const { pathname } = Router.useLocation();
    const isAccountPage = pathname.includes("/user/account");

    function handleLogoutClick() {
      Firebase.auth().signOut();
      window.location.reload();
    }

    return (
      <>
        {isAccountPage &&
          userStore.user &&
          (userStore.user as Model.User).phoneNumber && (
            <Mui.Button color="inherit" onClick={handleLogoutClick}>
              Logout
            </Mui.Button>
          )}
      </>
    );
  }
);

export default AccountAppBarLogoutButton;
