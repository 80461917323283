import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Component from "../../components";

export enum ITEM_INFO_VARIANT {
  LIST = "LIST",
  HERO = "HERO"
}

interface ItemInfoProps {
  available?: boolean;
  name: string;
  price: number;
  total?: number;
  quantity?: number;
  description?: string;
  tags?: string[];
  variant?: ITEM_INFO_VARIANT;
}

const ItemInfo: React.FC<ItemInfoProps> = props => {
  const isItemAvailable = () => {
    if (props.available === undefined) {
      return true;
    }
    return props.available;
  };
  return (
    <>
      <Mui.ListItemText
        disableTypography
        primary={
          <>
            <Mui.Grid container justify="space-between">
              {props.variant === ITEM_INFO_VARIANT.HERO ? (
                <Styled.Mui.TypographyBold variant="h5">
                  {props.tags && props.tags[0] === "veg" ? (
                    <svg height="15" width="15">
                      <rect
                        width="15"
                        height="15"
                        stroke="green"
                        strokeWidth="3"
                        fill="white"
                      />
                      <circle
                        cx="7.5"
                        cy="7.5"
                        r="4"
                        stroke="green"
                        strokeWidth="0"
                        fill="green"
                      />
                    </svg>
                  ) : (
                    props.tags &&
                    props.tags[0] === "non-veg" && (
                      <svg height="15" width="15">
                        <rect
                          width="15"
                          height="15"
                          stroke="red"
                          strokeWidth="3"
                          fill="white"
                        />
                        <circle
                          cx="7.5"
                          cy="7.5"
                          r="4"
                          stroke="red"
                          strokeWidth="0"
                          fill="red"
                        />
                      </svg>
                    )
                  )}
                  &nbsp;
                  {props.name}
                </Styled.Mui.TypographyBold>
              ) : (
                <Mui.Typography>
                  {props.tags && props.tags[0] === "veg" ? (
                    <svg height="15" width="15">
                      <rect
                        width="15"
                        height="15"
                        stroke="green"
                        strokeWidth="3"
                        fill="white"
                      />
                      <circle
                        cx="7.5"
                        cy="7.5"
                        r="4"
                        stroke="green"
                        strokeWidth="0"
                        fill="green"
                      />
                    </svg>
                  ) : (
                    props.tags &&
                    props.tags[0] === "non-veg" && (
                      <svg height="15" width="15">
                        <rect
                          width="15"
                          height="15"
                          stroke="red"
                          strokeWidth="3"
                          fill="white"
                        />
                        <circle
                          cx="7.5"
                          cy="7.5"
                          r="4"
                          stroke="red"
                          strokeWidth="0"
                          fill="red"
                        />
                      </svg>
                    )
                  )}
                  &nbsp;
                  {props.name}{" "}
                  {props.quantity && (
                    <Mui.Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      &nbsp;(x{props.quantity})
                    </Mui.Typography>
                  )}
                </Mui.Typography>
              )}
              <div>
                <Styled.Mui.TypographyBold
                  component="span"
                  variant={
                    props.variant === ITEM_INFO_VARIANT.HERO ? "h5" : undefined
                  }
                >
                  <Component.Price price={props.total || props.price} />
                </Styled.Mui.TypographyBold>
              </div>
            </Mui.Grid>
          </>
        }
        secondary={
          props.description || props.tags || !isItemAvailable() ? (
            <>
              <Mui.Grid container justify="space-between">
                <Mui.Typography
                  color="textSecondary"
                  variant="body2"
                  gutterBottom
                >
                  {props.description}
                </Mui.Typography>
                {!isItemAvailable() && (
                  <Styled.Mui.TypographyBold
                    color="textSecondary"
                    variant="body2"
                    gutterBottom
                  >
                    {`Not Available`}
                  </Styled.Mui.TypographyBold>
                )}
              </Mui.Grid>
            </>
          ) : (
            undefined
          )
        }
      />
    </>
  );
};

export default ItemInfo;
