import * as Model from "../../../../../../models";

export function isChecked(
  option: Model.Restaurant.Menu.Item.Customization.Option,
  checkedOptions?: Map<string, Model.Restaurant.Menu.Item.Customization.Option>
): boolean {
  return checkedOptions && checkedOptions.get(option.optionId as string)
    ? true
    : false;
}
