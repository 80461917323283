import * as React from "react";
import * as Formik from "formik";
import * as Yup from "yup";
import * as Mobx from "mobx-react-lite";
// import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as App from "../../app";
import * as Component from "../../components";

interface StaffMenuEditCategoryInfoProps {
  isNew?: boolean;
  arrayIndex: number;
  category: Model.Restaurant.Category;
  items: number;
}

const StaffMenuEditCategoryInfo: React.FC<StaffMenuEditCategoryInfoProps> = Mobx.observer(
  props => {
    const validationSchema = Yup.object<Model.Form.Restaurant.Category.Edit>({
      name: Yup.string()
        .max(100, "Max 100 characters allowed")
        .required("Category Name is required")
    });

    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );

    async function handleSubmit(
      { name }: Model.Form.Restaurant.Category.Edit,
      {
        setSubmitting
      }: Formik.FormikHelpers<Model.Form.Restaurant.Category.Edit>
    ) {
      if (props.isNew) {
        categoryEditStore.setEditedCategory(
          { ...props.category, name },
          props.arrayIndex
        );
        setSubmitting(false);
        return;
      }

      categoryEditStore.setEditedOriginalCategory(
        { ...props.category, name },
        props.arrayIndex
      );
      setSubmitting(false);
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          categoryEditStore.isEditable && (
            <Formik.Formik
              validationSchema={validationSchema}
              initialValues={{ name: props.category.name || "" }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Formik.Form style={{ width: "100%" }}>
                  <Formik.Field
                    name="name"
                    type="text"
                    component={Component.FormikMui.TextField.AutoSubmitting}
                    fullWidth
                    autoFocus={
                      props.category.categoryId === menuStore.expandedCategoryId
                        ? true
                        : false
                    }
                    placeholder="Category Name"
                    margin="dense"
                    required
                  />
                </Formik.Form>
              )}
            </Formik.Formik>
          )}
      </>
    );
  }
);

export default StaffMenuEditCategoryInfo;
