import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as Styled from "../../styles";
import * as App from "../../app";
import * as Model from "../../models";
import * as Formik from "formik";
import * as Yup from "yup";
import * as Component from "../../components";

interface StaffMenuItemEditInfoProps {}

const StaffMenuItemEditInfo: React.FC<StaffMenuItemEditInfoProps> = Mobx.observer(
  props => {
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const editMenuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Edit
    );

    const validationSchema = Yup.object<Model.Form.Restaurant.Menu.Item.Edit>({
      description: Yup.string().max(160, "Maximum 160 characters allowed"),
      extraDescription: Yup.string().max(160, "Maximum 160 characters allowed"),
      name: Yup.string().required("Name is required"),
      price: Yup.number()
        .min(0, "Price cannot be in negative")
        .required("Price is required"),
      tags: Yup.array(Yup.string())
    });

    function handleSubmit(
      values: Model.Form.Restaurant.Menu.Item.Edit,
      {
        setSubmitting
      }: Formik.FormikHelpers<Model.Form.Restaurant.Menu.Item.Edit>
    ) {
      editMenuItemStore.setEditedItem(values);
      setSubmitting(false);
    }

    return (
      <>
        {editMenuItemStore.item && (
          <Mui.ListItemText
            disableTypography
            primary={
              <>
                <Formik.Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: editMenuItemStore.item.name,
                    price: editMenuItemStore.item.price,
                    description: editMenuItemStore.item.description,
                    extraDescription: editMenuItemStore.item.extraDescription,
                    tags: editMenuItemStore.item.tags || []
                  }}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Formik.Form style={{ width: "100%" }}>
                      <Mui.Grid
                        container
                        spacing={0}
                        justify="space-between"
                        alignItems="center"
                      >
                        <Mui.Grid item xs>
                          <Styled.Mui.TypographyBold component="div">
                            <Formik.Field
                              name="name"
                              type="text"
                              component={
                                Component.FormikMui.TextField.AutoSubmitting
                              }
                              fullWidth
                              autoFocus={true}
                              placeholder="Item Name *"
                              margin="dense"
                              required
                            />
                          </Styled.Mui.TypographyBold>
                        </Mui.Grid>
                        <Mui.Grid item xs={3}>
                          <Styled.Mui.TypographyBold component="div">
                            <Formik.Field
                              name="price"
                              type="number"
                              component={
                                Component.FormikMui.TextField.AutoSubmitting
                              }
                              InputProps={{
                                startAdornment: (
                                  <Mui.InputAdornment position="start">
                                    {restaurantStore.restaurant &&
                                    restaurantStore.restaurant.currency
                                      ? restaurantStore.restaurant.currency
                                          .symbol
                                      : "₹"}
                                  </Mui.InputAdornment>
                                )
                              }}
                              fullWidth
                              placeholder={`Price *`}
                              margin="dense"
                              required
                            />
                          </Styled.Mui.TypographyBold>
                        </Mui.Grid>

                        <Mui.Grid item xs={12}>
                          <Formik.Field
                            name="description"
                            type="text"
                            component={
                              Component.FormikMui.TextField.AutoSubmitting
                            }
                            fullWidth
                            placeholder="Description"
                            multiline
                            margin="dense"
                          />
                        </Mui.Grid>

                        <Mui.Grid item xs={12}>
                          <Formik.Field
                            name="extraDescription"
                            type="text"
                            component={
                              Component.FormikMui.TextField.AutoSubmitting
                            }
                            fullWidth
                            placeholder="Extra Description"
                            multiline
                            margin="dense"
                          />
                        </Mui.Grid>
                      </Mui.Grid>
                    </Formik.Form>
                  )}
                </Formik.Formik>
              </>
            }
          />
        )}
      </>
    );
  }
);

export default StaffMenuItemEditInfo;
