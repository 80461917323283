/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Mobx from "mobx-react-lite";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as Router from "react-router-dom";
import * as Model from "../../models";
import * as ReactFire from "reactfire";
import * as App from "../../app";

interface MenuProps {}

const Menu: React.FC<MenuProps> = Mobx.observer(props => {
  const { restaurantId } = Router.useParams<Model.RouteParams.Menu>();

  const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
  const orderStore = React.useContext(App.Context.Restaurant.Order);
  const menuStore = React.useContext(App.Context.Restaurant.Menu.Menu);
  const categoryEditStore = React.useContext(
    App.Context.Restaurant.Category.Edit
  );

  const compareCategories = (
    a: Model.Restaurant.Category,
    b: Model.Restaurant.Category
  ): number => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };
  const restaurantRef = ReactFire.useFirebaseApp()
    .firestore()
    .collection("restaurants")
    .doc(restaurantId);

  const menuRef = restaurantRef.collection("menu");

  React.useEffect(() => {
    // restaurantStore.subscribe(restaurantId);
    // menuStore.subscribe(restaurantId);
    restaurantStore.subscribe(restaurantRef);
    menuStore.subscribe(menuRef, restaurantRef);
  }, []);

  React.useEffect(() => {
    if (menuStore.expandedCategoryId && menuStore.expandedCategoryId !== "") {
      return;
    }
    menuStore.setExpandedCategoryId(
      (restaurantStore.categories &&
        restaurantStore.categories.length > 0 &&
        restaurantStore.categories[0].categoryId) ||
        false
    );
  }, [restaurantStore.categories]);

  return (
    <>
      {restaurantStore.isFetching || menuStore.isFetching ? (
        <Component.PageLoadingIndicator />
      ) : (
        <>
          <Component.RestaurantCarousel
            images={
              restaurantStore.images && restaurantStore.images.length > 0
                ? restaurantStore.images
                : [
                    {
                      name: "image",
                      active: true,
                      url:
                        "https://firebasestorage.googleapis.com/v0/b/restaurant-36d1e.appspot.com/o/Restaurant%2Fhouse22.jpeg?alt=media&token=8fbb1ad0-4c54-4a6d-9237-058e78316c4f"
                    }
                  ]
            }
          />
          <Styled.Menu.MenuContainer>
            {/* <Container.RestaurantInfo /> */}
            {restaurantStore.categories &&
              restaurantStore.categories.length > 0 &&
              restaurantStore.categories
                .sort(compareCategories)
                .map((category, index) => (
                  <Container.MenuCategoryCard
                    key={`MenuCategory#${category.categoryId}-${index}`}
                    arrayIndex={index}
                    expandedCategoryId={menuStore.expandedCategoryId}
                    setExpandedCategoryId={menuStore.setExpandedCategoryId}
                    category={category}
                  />
                ))}
            {categoryEditStore.categories &&
              categoryEditStore.categories.length > 0 &&
              categoryEditStore.categories
                .sort(compareCategories)
                .map((category, index) => (
                  <Container.MenuCategoryCard
                    key={`NewAddedMenuCategory#${category.categoryId}-${index}`}
                    arrayIndex={index}
                    isNew={true}
                    expandedCategoryId={menuStore.expandedCategoryId}
                    setExpandedCategoryId={menuStore.setExpandedCategoryId}
                    category={category}
                  />
                ))}
            {orderStore.order && orderStore.order.items.length > 0 && (
              <Component.FixedFooter>
                <Container.OrderCheckoutButton />
              </Component.FixedFooter>
            )}
            <Container.StaffMenuAddCategoryCard />
            <Container.StaffMenuEditButton />
            <Mui.Grid container spacing={2}>
              <Mui.Grid item xs={6}>
                <Container.StaffMenuEditCancelButton />
              </Mui.Grid>
              <Mui.Grid item xs={6}>
                <Container.StaffMenuEditSaveButton />
              </Mui.Grid>
            </Mui.Grid>
          </Styled.Menu.MenuContainer>
        </>
      )}
    </>
  );
});

export default Component.withTracker(Menu);
