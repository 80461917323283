import * as React from "react";
// import * as Mui from "@material-ui/core";
import * as Model from "../../models";
import * as Styled from "../../styles";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";

// const AutoSwipeableViews = autoPlay(SwipeableViews);

interface RestaurantCarouselProps {
  images?: Model.Restaurant.Image[];
}

const RestaurantCarousel: React.FC<RestaurantCarouselProps> = props => {
  // const theme = Mui.useTheme();

  // const [activeStep, setActiveStep] = React.useState<number>(0);

  // const handleStepChange = (step: number) => {
  //   setActiveStep(step);
  // };

  return (
    <>
      {props.images && props.images.length > 0 && (
        <>
          <Styled.Mui.CardMedia image={props.images[0].url} title={props.images[0].name} />
        </>
      )}
      {/* {props.images && props.images.length > 0 && (
        <>
          <AutoSwipeableViews
            style={{ zIndex: "0 !important" }}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {props.images.map((image, index) => (
              <React.Fragment key={image.name}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Styled.Mui.CardMedia image={image.url} title={image.name} />
                ) : null}
              </React.Fragment>
            ))}
          </AutoSwipeableViews>
        </>
      )} */}
    </>
  );
};

export default RestaurantCarousel;
