import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface MenuItemQuantitySelectCardProps {
  max?: number;
  min?: number;
  step?: number;
}

const MenuItemQuantitySelectCard: React.FC<MenuItemQuantitySelectCardProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const menuItemStore = React.useContext(
      App.Context.Restaurant.Menu.Item.Item
    );

    return (
      <>
        {menuItemStore.menuItem && !userStore.isStaff && (
          <Mui.ExpansionPanel expanded={true}>
            <Styled.Mui.ExpansionPanelSummary>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="h6">Quantity</Mui.Typography>
                <Mui.Typography variant="body2" color="textSecondary">
                  {menuItemStore.quantityCounter} / {props.max}
                </Mui.Typography>
              </Mui.Grid>
            </Styled.Mui.ExpansionPanelSummary>
            <Mui.Divider />
            <Styled.Mui.ExpansionPanelDetailsDensePadding>
              <Mui.Grid container justify="center" alignItems="baseline">
                <Component.Counter
                  counter={menuItemStore.quantityCounter}
                  setCounter={menuItemStore.setQuantityCounter}
                  min={props.min}
                  max={props.max}
                  step={props.step}
                />
              </Mui.Grid>
            </Styled.Mui.ExpansionPanelDetailsDensePadding>
          </Mui.ExpansionPanel>
        )}
      </>
    );
  }
);

MenuItemQuantitySelectCard.defaultProps = {
  max: 10,
  min: 1,
  step: 1
};

export default MenuItemQuantitySelectCard;
