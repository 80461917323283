import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as App from "../../app";
import * as Mobx from "mobx-react-lite";

interface OrderAmountInfoProps {}

const OrderAmountInfo: React.FC<OrderAmountInfoProps> = Mobx.observer(props => {
  const orderStore = React.useContext(App.Context.Restaurant.Order);

  return (
    <>
      <Styled.Mui.ListItemNoPadding>
        <Mui.ListItemText
          primary={
            <>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="body2">Subtotal</Mui.Typography>
                <Styled.Mui.TypographyBold variant="body2">
                  <Component.Price price={orderStore.subTotal} />
                </Styled.Mui.TypographyBold>
              </Mui.Grid>
            </>
          }
        />
      </Styled.Mui.ListItemNoPadding>
      <Styled.Mui.ListItemNoPadding>
        <Mui.ListItemText
          primary={
            <>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="body2">Taxes & Fees</Mui.Typography>
                <Styled.Mui.TypographyBold variant="body2">
                  <Component.Price price={orderStore.taxTotal} />
                </Styled.Mui.TypographyBold>
              </Mui.Grid>
            </>
          }
        />
      </Styled.Mui.ListItemNoPadding>
      { orderStore.order && orderStore.order.isDiscountApplied && <Styled.Mui.ListItemNoPadding>
        <Mui.ListItemText
          primary={
            <>
              <Mui.Grid container justify="space-between" alignItems="center">
                <Mui.Typography variant="body2">Total Discount</Mui.Typography>
                <Styled.Mui.TypographyBold variant="body2">
                  <Component.Price price={orderStore.totalDiscount} />
                </Styled.Mui.TypographyBold>
              </Mui.Grid>
            </>
          }
        />
      </Styled.Mui.ListItemNoPadding>}
    </>
  );
});

export default OrderAmountInfo;
