/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import * as Router from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as Styled from "../../styles";
import * as Component from "../../components";
import * as Container from "../../containers";
import * as App from "../../app";
import * as Hook from "../../hooks";
import * as Mobx from "mobx-react-lite";

interface OrderProps {}

const Order: React.FC<OrderProps> = Mobx.observer(props => {
  const userStore = React.useContext(App.Context.User.User);
  const orderStore = React.useContext(App.Context.Restaurant.Order);
  const staffOrderStore = React.useContext(App.Context.Staff.Order);

  let isComponentLoaded: boolean;
  const history = Router.useHistory();

  // NOTE: For staff new order notification tone
  React.useEffect(() => {
    staffOrderStore.pauseNotificationTone(orderStore.order);
    return () => {
      staffOrderStore.playNotificationTone();
    };
  }, []);

  React.useEffect(() => {
    isComponentLoaded = true;
    return () => {
      isComponentLoaded = false;
      const isPlacedOrder: boolean =
        !userStore.isStaff && orderStore.order && orderStore.order.orderId
          ? true
          : false;
      orderStore.reset();
      if (isPlacedOrder) {
        history.push("/user/account");
      }
    };
  }, []);

  const [isCheckoutAvailable, setIsCheckoutAvailable] = React.useState<boolean>(
    false
  );

  Hook.Checkout.useCashfreeCheckout({
    callback: () => {
      if (isComponentLoaded) {
        setIsCheckoutAvailable(true);
      }

      if (!window.CashFree) {
        return;
      }

      window.CashFree.init({
        layout: {
          view: "popup",
          width: 650
        },
        mode: "TEST"
      });
    }
  });

  return (
    <>
      {userStore.isStaff && !orderStore.order && <Router.Redirect to="/" />}
      <Component.InfoDialog
        open={orderStore.openCreateOrderAbortedDialog ? true : false}
        title={() => <>Cannot place your order</>}
        body={() => <>{orderStore.openCreateOrderAbortedDialog}</>}
        action={() => (
          <>
            <Mui.Button
              color="primary"
              onClick={() => orderStore.setOpenCreateOrderAbortedDialog()}
            >
              OK
            </Mui.Button>
          </>
        )}
        handleClose={() => orderStore.setOpenCreateOrderAbortedDialog()}
      />
      <Styled.Page.Container>
        {/* <Container.BackButton />
        <Styled.Page.Title>
          <Styled.Mui.TypographyBold variant="h5">
            My Order
          </Styled.Mui.TypographyBold>
        </Styled.Page.Title>
        <Mui.Typography variant="body2" color="textSecondary">
          <Mui.Typography component="span" color="error">
            *&nbsp;
          </Mui.Typography>
          Govt. taxes as applicable.
        </Mui.Typography> */}
        {orderStore.order &&
        orderStore.order.items &&
        orderStore.order.items.length > 0 ? (
          <Container.OrderOverviewCard />
        ) : (
          <>
            <Component.ErrorInfo
              title="No items found."
              body="Hungry? Add some items from the menu."
              picture="/images/no-order.svg"
            />
            {/* <Mui.Grid
              style={{ height: "50vh" }}
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Mui.Typography variant="h6">No items found.</Mui.Typography>
              <Mui.Typography variant="body2">
                Hungry? Add some items from the menu.
              </Mui.Typography>
            </Mui.Grid> */}
          </>
        )}
        <Container.OrderCouponCode />
        {orderStore.order &&
          orderStore.order.items &&
          orderStore.order.items.length > 0 && (
            <Component.FixedFooter
              background={Component.FIXED_FOOTER_BACKGROUND.WHITE}
              padding={Component.FIXED_FOOTER_PADDING.LARGE}
            >
              <Mui.List dense>
                <Container.OrderAmountInfo />
                <Container.OrderPaymentInfo />
                <Styled.Mui.Divider />
                <Container.OrderTotalInfo />
              </Mui.List>
              {false && (
                <Container.OrderContinueCheckoutButton
                  disabled={!isCheckoutAvailable}
                />
              )}
              {true && (
                <Container.OrderContinueCheckoutWithCashfreeButton
                  disabled={!isCheckoutAvailable}
                />
              )}
              {false && (
                <Container.OrderRetryPaymentButton
                  disabled={!isCheckoutAvailable}
                />
              )}
              {true && (
                <Container.OrderRetryCashfreePaymentButton
                  disabled={!isCheckoutAvailable}
                />
              )}
              <Container.OrderLoginToContinueAction />
              <Container.StaffOrderAcceptButton />
              <Container.StaffOrderReadyButton />
              <Container.StaffOrderCloseButton />
            </Component.FixedFooter>
          )}
      </Styled.Page.Container>
    </>
  );
});

export default Component.withTracker(Order);
