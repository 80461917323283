import * as React from "react";
import * as Mobx from "mobx-react-lite";
import * as Mui from "@material-ui/core";
import * as App from "../../app";

interface StaffMenuEditCancelButtonProps {}

const StaffMenuEditCancelButton: React.FC<StaffMenuEditCancelButtonProps> = Mobx.observer(
  props => {
    const userStore = React.useContext(App.Context.User.User);
    const restaurantStore = React.useContext(App.Context.Restaurant.Restaurant);
    const categoryEditStore = React.useContext(
      App.Context.Restaurant.Category.Edit
    );
    const menuEditStore = React.useContext(App.Context.Restaurant.Menu.Edit);

    function handleClick() {
      categoryEditStore.reset();
      menuEditStore.reset();
    }

    return (
      <>
        {userStore.isStaff &&
          restaurantStore.restaurant &&
          (menuEditStore.isEditable || categoryEditStore.isEditable) && (
            <Mui.Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={handleClick}
            >
              Cancel
            </Mui.Button>
          )}
      </>
    );
  }
);

export default StaffMenuEditCancelButton;
