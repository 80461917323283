import * as React from "react";
import * as Styled from "../../styles";
import * as Mui from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import * as MuiTransition from "@material-ui/core/transitions";
import * as Layout from "../../layouts";
import * as Yup from "yup";
import * as Formik from "formik";
import * as FormikMui from "formik-material-ui";
import * as Model from "../../models";
import * as Container from "../../containers";

interface MobileOTPDialogProps {
  open: boolean;
  handleClose(): void;
}

const Transition = React.forwardRef<unknown, MuiTransition.TransitionProps>(
  function Transition(props, ref) {
    return <Mui.Slide direction="up" ref={ref} {...props} />;
  }
);

const MobileOTPDialog: React.FC<MobileOTPDialogProps> = props => {
  const validationSchema = Yup.object<Model.Form.MobileOTP>({
    otp: Yup.string()
      .matches(/^\d{6}$/, "OTP is invalid")
      .required("OTP is required")
  });

  async function handleSubmit(
    { otp }: Model.Form.MobileOTP,
    { setSubmitting, setFieldError }: Formik.FormikHelpers<Model.Form.MobileOTP>
  ) {
    try {
      if (!window.confirmationResult) {
        return;
      }

      await window.confirmationResult.confirm(otp.trim());
      props.handleClose();
    } catch (error) {
      console.error(error);
      if (error.code === "auth/invalid-verification-code") {
        setFieldError("otp", "OTP is invalid");
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Mui.Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        PaperComponent={Styled.Page.Paper}
      >
        <Layout.Dev variant={Layout.VARIANT.DIALOG}>
          <Container.AppBar
            title={<Mui.Typography variant="h6">Mobile OTP</Mui.Typography>}
            // TODO: This is a cheap fix done to remove the default back button from
            // the AppBar refactor it when time allows
            startActionArea={<></>}
            endActionArea={
              <>
                <Mui.IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => props.handleClose()}
                >
                  <Icon.Close />
                </Mui.IconButton>
              </>
            }
          />
          <Styled.Page.Container>
            {/* <Styled.Mui.TypographyBold variant="h5">
              Mobile OTP
            </Styled.Mui.TypographyBold> */}
            <Mui.Typography variant="body2" color="textSecondary" gutterBottom>
              Enter the otp recieved on your mobile in the input below.
            </Mui.Typography>
            <Formik.Formik
              initialValues={{ otp: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Formik.Form>
                  <Mui.Grid container spacing={1}>
                    <Mui.Grid item xs={12}>
                      <Formik.Field
                        type="tel"
                        name="otp"
                        component={FormikMui.TextField}
                        label="OTP"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.Button
                        type="submit"
                        variant="contained"
                        disabled={!isValid || isSubmitting}
                        fullWidth
                        color="primary"
                      >
                        {isSubmitting ? <Mui.CircularProgress /> : "Submit"}
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                </Formik.Form>
              )}
            </Formik.Formik>
          </Styled.Page.Container>
        </Layout.Dev>
      </Mui.Dialog>
    </>
  );
};

export default MobileOTPDialog;
